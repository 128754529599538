(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('statements', ['$state', 'Info', function($state, Info) {
            return {
                restrict: 'E',
                template: "<div class='info success' ng-repeat='statement in statements'></span><span class='info-left success-left glyphicon glyphicon-ok'></span><span ng-bind-html='$sce.trustAsHtml(statement)'></span></div>",
                link: function(scope, element, attrs) {
                    //If container is undefined
                    if (Info.statements[$state.current.name] == undefined)
                    //Initialize array
                        Info.statements[$state.current.name] = [];
                    //Give a reference
                    scope.statements = Info.statements[$state.current.name];
                }
            };
        }]);
})();
