(function() {
    'use strict';
	
    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('invalid', function(){
			
			            return {
				    restrict: 'A',
				    link: function(scope, element, attrs){
					    $(element).change(function(){
						    if(!this.classList.contains("ng-invalid")){
							    $(element).tooltip('hide');
						}else{
							    $(element).tooltip('show');
						}			
					});
				}
			};
		        });
})();
