(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('newUserEmail', {
            parent: 'application',
            url: '/user/newEmail',
            params: { userName: null, },
            data: {
                authorities: ['USER_RESOURCE'],
                pageTitle: 'Aktualizuj email użytkownika - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/newUserEmail/newUserEmail.html',
                    controller: 'newUserEmailController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
