(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('DocumentsService', DocumentsService);

    DocumentsService.$inject = ['API_URL', '$http'];

    function DocumentsService(API_URL, $http) {

        return {
            getDocuments: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents',
                    params: filter
                });
            },
            getDocumentsPaged: function(filter, pageNum) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/page/' + pageNum,
                    params: filter
                });
            },
            getDocument: function(transaction_id, template, login) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/' + transaction_id + '/' + template + '/download',
                    params: {
                        login: login
                    },
                    responseType: 'arraybuffer'
                });
            },
            downloadMergedPDF: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/download',
                    params: filter,
                    responseType: 'arraybuffer'
                });
            },
            downloadMergedPDFPaged: function(filter, pageNum) {
                 return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/download/page/' + pageNum,
                    params: filter,
                    responseType: 'arraybuffer'
                 });
            },
            getXML: function(transaction_id, login) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/' + transaction_id + '/download',
                    params: {
                        login: login
                    },
                    responseType: 'arraybuffer'
                });
            },
            getExcelSpreadsheet: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/excel',
                    params: filter,
                    responseType: 'arraybuffer'
                });
            },
            getExcelSpreadsheetPaged: function(filter, pageNum) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/excel/page/' + pageNum,
                    params: filter,
                    responseType: 'arraybuffer'
                });
            },
            getDocumentTypes: function(login) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/types?login=' + encodeURIComponent(login)
                });
            },
            getAdditionalInfo: function(login) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/documents/additionalInfo?login=' + encodeURIComponent(login)
                });
            }
        };
    }
})();
