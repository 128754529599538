(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope', '$state', 'Auth', 'Info'];

    function LoginController($rootScope, $state, Auth, Info) {
        var vm = this;

        vm.credentials = {
            login: null,
            password: null,
            remember: false
        };

        //Declare functions
        vm.login = login;

        function login() {
            Info.clear();

            //Validate if all inputs are not empty
            if ($('input[type=password].ng-valid.ng-not-empty').length == 0 || $('input[type=text].ng-valid.ng-not-empty').length == 0) {
                Info.addError('Podaj kod klienta / login oraz hasło.');
            } else {
                $("#loginForm").addClass('form-success');
                $(".content").addClass('hidden');
                $('.loader').removeClass('hidden');
                //Login
                Auth.login(vm.credentials)
                    //When successful
                    .then(function(response) {
                        //Go to the documents state
                        $state.go($rootScope.afterAuth || 'documents');
                        $rootScope.afterAuth = undefined;
                    })
                    //When not successful
                    .catch(function(response) {
                        if (response.data == null) {
                            Info.addError('Wystąpił błąd podczas próby zalogowania. Prosimy o kontakt z Działem Obsługi Klienta na adres e-mail: <a href="mailto:portal.klienta@vbl.pl">portal.klienta@vbl.pl</a>.');
                            $("#loginForm").removeClass('form-success');
                            $(".content").removeClass('hidden');
                            $('.loader').addClass('hidden');
                        } else if (response.data.error == 4011) {
                            $state.go('passwordExpired', { login: vm.credentials.login, currentValue: vm.credentials.password }).then(function() {
                                $("#loginForm").removeClass('form-success');
                                $(".content").removeClass('hidden');
                                $('.loader').addClass('hidden');
                            });
                        } else {
                            Info.addErrorCode(response.data.error, 'login', response.data.result);
                            $("#loginForm").removeClass('form-success');
                            $(".content").removeClass('hidden');
                            $('.loader').addClass('hidden');
                        }
                    });
            }
        }
    }
})();
