(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('passwordReset', {
            parent: 'site',
            url: '/passwordReset?token',
            params: {
                token: null
            },
            data: {
                authorities: [],
                pageTitle: 'Resetowanie hasła - VB Leasing'
            },
            views: {
                'index@': {
                    templateUrl: 'app/views/passwordReset/passwordReset.html',
                    controller: 'PasswordResetController',
                    controllerAs: 'vm'
                },
                'step@passwordReset': {
                    templateUrl: 'app/views/passwordReset/passwordReset-Reset.html',
                }
            }
        }).state('passwordReset-Success', {
            parent: 'passwordReset',
            url: '/success',
            views: {
                'step@passwordReset': {
                    templateUrl: 'app/views/passwordReset/passwordReset-Success.html'
                }
            }
        }).state('passwordReset-Confirm', {
            parent: 'passwordReset',
            url: '/confirm',
            views: {
                'step@passwordReset': {
                    templateUrl: 'app/views/passwordReset/passwordReset-Confirm.html'
                }
            },
            resolve: {
                change: function($q, $timeout, $state, $stateParams, Utils, PasswordResetService) {
                    var deferred = $q.defer();

                    // $timeout is an example; it also can be an xhr request or any other async function
                    $timeout(function() {
                        if (Utils.isEmpty($stateParams.token)) {
                            $state.go('login');
                            deferred.reject();
                        } else {
                            PasswordResetService.getChange($stateParams)
                                .then(function(response) {
                                    if (response.data.result != null) {
                                        deferred.resolve(response.data.result);
                                    } else {
                                        $state.go('login');
                                        deferred.reject();
                                    }
                                })
                                .catch(function(response) {
                                    $state.go('passwordReset-Fail-' + response.data.errorCode);
                                    deferred.reject();
                                });
                        }
                    });

                    return deferred.promise;
                }
            }
        }).state('passwordReset-Confirm-Success', {
            parent: 'passwordReset',
            url: '/confirm/success',
            views: {
                'step@passwordReset': {
                    templateUrl: 'app/views/passwordReset/passwordReset-Confirm-Success.html'
                }
            }
        }).state('passwordReset-Fail', {
            parent: 'passwordReset',
            url: '/fail',
            views: {
                'step@passwordReset': {
                    templateUrl: 'app/views/passwordReset/passwordReset-Fail.html'
                }
            }
        });

        var errors = [208, 403, 408, 1027, 1028, 1029, 1031, 2013, 2016];
        //Add all error states
        for (var errorIndex in errors) {
            $stateProvider.state('passwordReset-Fail-' + errors[errorIndex], {
                parent: 'passwordReset-Fail',
                url: '/' + errors[errorIndex],
                views: {
                    'fail@passwordReset-Fail': {
                        templateUrl: 'app/views/passwordReset/passwordReset-Fail-' + errors[errorIndex] + '.html'
                    }
                }
            });
        }
    }
})();
