(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account'];

    function Principal($q, Account) {
        var _identity,
            _authenticated = false;

        var service = {
            getIdentity: getIdentity,
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved,
            hasRoles: hasRoles,
            hasPermissions: hasPermissions,
            getProperty: getProperty,
            isClient: isClient,
            setProperty: setProperty,
            getLogin: getLogin,
            isEInvoice: isEInvoice,
            getLastLoginDate: getLastLoginDate,
            updateLastAccessedTime: updateLastAccessedTime
        };

        return service;

        function getIdentity() {
            return _identity;
        }

        function getLogin() {
            if (_identity !== undefined) {
                return _identity.login;
            }
        }

        function isEInvoice() {
            if (_identity !== undefined) {
                return _identity.e_invoice;
            }
        }

        function getLastLoginDate() {
            if (_identity !== undefined) {
                return _identity.lastLoginDate;
            }
        }

        function isClient() {
            return service.hasAuthority('ROLE_CLIENT');
        }

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function() {
                return false;
            });
        }

        function identity(force) {
            var deferred = $q.defer();

            /*if (force === true) {
                _identity = null;
                _authenticated = false;
            }*/
            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (force !== true && _identity != null && _identity != undefined) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account.data;
                _authenticated = account.data ? true : false;
                updateLastAccessedTime();
                deferred.resolve(_identity);
            }

            function getAccountCatch() {
                _identity = null;
                _authenticated = false;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }

        function hasRoles(roles) {
            if (!_authenticated || !_identity || !_identity.roles) {
                return false;
            }

            if (!roles) {
                return true;
            }

            for (var i = 0; i < roles.length; i++) {
                if (_identity.roles.indexOf(roles[i]) == -1) {
                    return false;
                }
            }
            return true;
        }

        function hasPermissions(permissions) {
            if (!_authenticated || !_identity || !_identity.permissions) {
                return false;
            }

            if (!permissions) {
                return true;
            }

            for (var i = 0; i < permissions.length; i++) {
                if (_identity.permissions.indexOf(permissions[i]) == -1) {
                    return false;
                }
            }
            return true;
        }

        function getProperty(property) {
            if (!property || !_identity) {
                return null;
            }
            return _identity[property];
        }

        function setProperty(property, value) {
            if (_identity)
                _identity[property] = value;
        }

        function updateLastAccessedTime() {
            setProperty('sessionLastAccess', new Date().getTime());
        }
    }
})();
