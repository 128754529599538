(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('ShellController', ShellController);

    ShellController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', 'ShellService', 'Info'];

    function ShellController($rootScope, $scope, $state, Auth, Principal, ShellService, Info) {
        var vm = this;

        //Declare function
        vm.toggleSidebar = toggleSidebar;
        vm.showSidebar = showSidebar;
        vm.hideSidebar = hideSidebar;
        vm.logout = logout;
        vm.name = Principal.getProperty('displayName').indexOf('|') != -1 ?
            Principal.getProperty('displayName').slice(0, Principal.getProperty('displayName').indexOf('|')) :
            Principal.getProperty('displayName');

        function showSidebar() {
            var toggle = $('.navbar-toggle');
            setTimeout(function() {
                toggle.addClass('toggled');
            }, 430);

            $('html').addClass('nav-open');
        }

        function hideSidebar(hideAnimation) {
            var toggle = $('.navbar-toggle');
            if (hideAnimation)
                $('html').css("transition", "none");
            $('html').removeClass('nav-open');
            setTimeout(function() {
                toggle.removeClass('toggled');
                $('html').css("transition", "all 0.3s ease-in-out");
            }, 430);
        }

        function toggleSidebar() {
            var navbar = $('.navbar-collapse');
            if ($rootScope.sidebar == undefined) {
                $rootScope.sidebar = true;
            } else {
                navbar.css('min-height', window.screen.height);
                $rootScope.sidebar = !$rootScope.sidebar;
            }

            if ($rootScope.sidebar) {
                showSidebar();
            } else {
                hideSidebar();
            }
        }

        function logout() {
            Info.setAction('Wylogowywanie...');
            //Logout through HTTP request
            Auth.logout().finally(function() {
                //Go to login view
                $state.go('login');
                $("[role='tooltip']").remove(); //Remove tooltips
                Info.clearAction();
            });
        }

        function showTimeout(withSeconds) {
            var timeoutIndicator = $('#sessionTimeout');
            var timeout, minutes, seconds;
            return setInterval(function() {
                timeout = Principal.getProperty('sessionLastAccess') + Principal.getProperty('sessionLength') - new Date().getTime();
                if (timeout < 0) {
                    logout();
                } else if (withSeconds) {
                    minutes = Math.floor(timeout / 60000);
                    seconds = Math.floor((timeout - minutes * 60000) / 1000);
                    timeoutIndicator.text(minutes + (seconds < 10 ? ":0" + seconds : ":" + seconds));
                } else {
                    minutes = Math.ceil(timeout / 60000);
                    timeoutIndicator.text(minutes + " min");
                }

            }, 1000);
        }

        function getCommunique() {
             var communiqueln1 = $('#communiqueln1');
             var communiqueln2 = $('#communiqueln2');

            ShellService.communique1("a").then(function(response){
              communiqueln1.text(response.data.result);
            }).catch(function(response){
            communiqueln1.text("error");
            });

            ShellService.communique2("a").then(function(response){
              communiqueln2.text(response.data.result);
            }).catch(function(response){
            communiqueln2.text("error");
            });
            }


        getCommunique();
        var timer = showTimeout(true);

        $scope.$on("$destroy", function() {
            if (timer) {
                clearInterval(timer);
            }
        });
    }
})();
