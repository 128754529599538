(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('passwordExpired', {
            parent: 'site',
            url: '/passwordExpired',
            params: {
                login: null,
                currentValue: null
            },
            data: {
                authorities: [],
                pageTitle: 'Zmiana hasła- VB Leasing'
            },
            views: {
                'index@': {
                    templateUrl: 'app/views/passwordExpired/passwordExpired.html',
                    controller: 'PasswordExpiredController',
                    controllerAs: 'vm'
                },
                'step@passwordExpired': {
                    templateUrl: 'app/views/passwordExpired/passwordExpired-Change.html',
                }
            },
            resolve: {
                params: function($q, $timeout, $state, $stateParams, Utils) {
                    var deferred = $q.defer();

                    // $timeout is an example; it also can be an xhr request or any other async function
                    $timeout(function() {
                        if (Utils.isEmpty($stateParams.login) || Utils.isEmpty($stateParams.currentValue)) {
                            $state.go('login');
                            deferred.reject();
                        } else {
                            deferred.resolve(true);
                        }
                    });

                    return deferred.promise;
                }
            }
        }).state('passwordExpired-Success', {
            parent: 'passwordExpired',
            url: '/success',
            data: {
                authorities: [],
                pageTitle: 'Zmiana hasła- VB Leasing'
            },
            views: {
                'step@passwordExpired': {
                    templateUrl: 'app/views/passwordExpired/passwordExpired-Success.html',
                }
            }
        });
    }
})();
