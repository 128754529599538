(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('datepicker', ['Utils', function(Utils) {
            return {
                require: 'ngModel',
                restrict: 'E',
                replace: true,
                template: "<input type='text' title='' data-toggle='tooltip' data-trigger='focus' data-html='true' data-container='body'>",
                link: function(scope, element, attributes, control) {

                    //MODEL -> VIEW
                    control.$formatters.push(function(value) {
                        var newValue = parseInt(value);
                        if (Utils.isEmpty(newValue)) {
                            console.log("Empty :(");
                            return '';
                        }
                        return Utils.getDate(newValue);
                    });

                    //VIEW -> MODEL
                    control.$parsers.push(jQuery.proxy(function(value) {
                        var newValue = value;
                        if (Utils.isNotDate(newValue))
                            return null;
                        var result = new Date(0);
                        result.setSeconds(0);
                        result.setMinutes(0);
                        result.setHours(0);
                        result.setDate(newValue.split('.')[0]);
                        result.setMonth(newValue.split('.')[1] - 1);
                        result.setYear(newValue.split('.')[2]);
                        //If this date is 'to' date then include the whole day
                        if (this.attr('name') == 'to')
                            result = result.getTime() + (1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*h*/ ) - 1 /*do not include next day*/ ;
                        else
                            result = result.getTime();
                        return result;
                    }, element));

                    //Validate minimum
                    control.$validators.min = jQuery.proxy(function(value) {
                        var minimum = 0;
                        if (!Utils.isNotAmount($(this).attr('data-min')))
                            minimum = $(this).attr('data-min');
                        return (control.$isEmpty(value) || (value >= minimum));
                    }, element);

                    //Validate maximum
                    control.$validators.max = jQuery.proxy(function(value) {
                        var maximum = new Date();
                        maximum.setSeconds(0);
                        maximum.setMinutes(0);
                        maximum.setHours(0);
                        maximum = new Date(maximum.getTime() + (1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*h*/ ) - 1 /*do not include next day*/ );
                        if (!Utils.isNotAmount($(this).attr('data-max')))
                            maximum = new Date(parseInt($(this).attr('data-max')));
                        else if ($(this).attr('max-constraint') == "false")
                            maximum.setFullYear(maximum.getFullYear() + 7);
                        return (control.$isEmpty(value) || (value <= maximum.getTime()));
                    }, element);

                    //Validate date format
                    control.$validators.date = jQuery.proxy(function(value) {
                        return (Utils.isEmpty(control.$viewValue) || !Utils.isEmpty(value));
                    }, element);

                    //Live validation tooltip
                    $(element).on('keyup change', function(event) {
                        var tooltip = '';
                        if (control.$error.min)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj datę późniejszą niż " + new Date(parseInt($(this).attr('data-min')) || 0).toLocaleDateString() + ".</p>";
                        if (control.$error.max)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj datę wcześniejszą niż " + new Date(parseInt($(this).attr('data-max')) || new Date().getTime()).toLocaleDateString() + ".</p>";
                        if (control.$error.date)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj prawidłową datę, np.: 02.02.2016</p>";

                        if (control.$valid) {
                            $(this).attr('data-original-title', tooltip);
                            $(this).tooltip('hide');
                        } else if (tooltip != $(this).attr('data-original-title')) {
                            $(this).attr('data-original-title', tooltip);
                            if ($(this)[0] == document.activeElement)
                                $(this).tooltip('show');
                        }
                    });

                    //Datepicker
                    $(element).datepicker({
                        autoclose: true,
                        maxViewMode: 2,
                        daysOfWeekHighlighted: [0, 6],
                        clearBtn: true,
                        language: "pl",
                        startDate: '-7y',
                        endDate: $(element).attr('max-constraint') == "false" ? '+7y' : '0d',
                        weekStart: 1,
                        todayHighlight: true,
                        format: 'dd.mm.yyyy'
                    });

                    //Hide datepicker on enter
                    $(element).datepicker().on('keydown', function(e) {
                        if (e.which == 13) $(this).datepicker('hide');
                    });

                    var family = $(element).attr('family');

                    if ($(element).attr('name') == "from") {
                        $(element).datepicker()
                            .on('changeDate clearDate', function() {
                                if ($(this).datepicker('getDate') != null)
                                    $("input.date-picker[name='to'][family=" + family + "]").datepicker('setStartDate', $(this).datepicker('getDate'));
                                else
                                    $("input.date-picker[name='to'][family=" + family + "]").datepicker('setStartDate', '-7y');
                            });
                    } else if ($(element).attr('name') == "to") {
                        $(element).datepicker()
                            .on('changeDate clearDate', function() {
                                var familyElements = $("input.date-picker[name='from'][family=" + family + "]");

                                $.each(familyElements, function(index, elem) {
                                    if ($(element).datepicker('getDate') != null)
                                        $(elem).datepicker('setEndDate', $(element).datepicker('getDate'));
                                    else
                                        $(elem).datepicker('setEndDate', elem.attributes["max-constraint"] && elem.attributes["max-constraint"].nodeValue == "false" ? '+7y' : '0d');
                                });

                            });
                    }
                }
            };
        }]);

})();
