(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('QuickActivate1Controller', QuickActivate1Controller);

    QuickActivate1Controller.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils'];

    function QuickActivate1Controller($state, $stateParams, $location, RegisterService, Info) {
        var vm = this;

        vm.data = {
            agreement1: true,
            agreement3: false
        };

        vm.tokenData = {
            token: null
        }

        vm.getData = getData;
        vm.spreadApproval = spreadApproval;

        angular.copy($stateParams, vm.tokenData);

        getData();

        function getData() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            RegisterService.getApprovalData(vm.tokenData)
                .then(function(response) {
                    vm.data.agreement3 = response.data.result;
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }

        function spreadApproval() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            RegisterService.spreadApproval(vm.tokenData)
                .then(function() {
                    $state.go('quickActivate2');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }
    }
})();
