(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('newUserEmailController', newUserEmailController);

    newUserEmailController.$inject = ['$rootScope', '$state', '$stateParams', 'AccountService', 'Info'];

    function newUserEmailController($rootScope, $state, $stateParams, AccountService, Info) {
        var vm = this;

        //Declare variables
        vm.data = {
            email: {
                newValue: null,
                newRepeat: null,
                login: $stateParams.userName
            }
        };

        //Declare functions
        vm.changeUserEmail = changeUserEmail;
        vm.init = init;

        function changeUserEmail() {
            AccountService.changeEmailByAdmin(vm.data.email)
                .then(function(response) {
                    Info.addStatement('Pomyślnie zmieniono adres email użytkownika ' + vm.data.login + 
                        '. Na podany adres e-mail została wysłana wiadomość umożliwiająca nowemu użytkownikowi uzyskanie dostępu do Portalu.', 
                        'users');
                    $state.go('users');
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function init(){
            if (vm.data.email.login == null || vm.data.email.login == undefined) {
                $state.go('users');
            }
        }

        vm.init();
    }
})();
