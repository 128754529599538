(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('newClientEmailController', newUserEmailController);

    newUserEmailController.$inject = ['$rootScope', '$state', '$stateParams', 'AccountService', 'Info'];

    function newUserEmailController($rootScope, $state, $stateParams, AccountService, Info) {
        var vm = this;

        //Declare variables
        vm.data = {
            login: $stateParams.clientLogin,
            newValue: null,
            newRepeat: null
        };

        //Declare functions
        vm.changeUserEmail = changeUserEmail;
        vm.init = init;

        function changeUserEmail() {
            AccountService.changeEmailByAdmin(vm.data)
                .then(function(response) {
                    Info.addStatement('Pomyślnie zmieniono adres email klienta ' + vm.data.login +
                        '. Na podany adres e-mail została wysłana wiadomość umożliwiająca klientowi uzyskanie dostępu do Portalu.',
                        'client');
                    $state.go('client');
                })
                .catch(function(response) {
                    if (response.data.errorCode == null) {
                        Info.addError('Brak uprawnień')
                    } else {
                        Info.addErrorCode(response.data.errorCode);
                    }
                });
        }

        function init() {
            if (vm.data.login == null || !angular.isDefined(vm.data.login)) {
                $state.go('client');
            }
        }

        vm.init();
    }
})();
