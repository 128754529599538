(function() {
    'use strict';
	
    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('principalRoles', ['Principal', function(Principal) {

			              return function(scope, element, attrs) {
				    var roles  = attrs.principalRoles.split(",");
				    if(!Principal.hasRoles(roles))
					    element.hide();
			};
		        }]);
})();
