(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('newUserController', newUserController);

    newUserController.$inject = ['$rootScope', '$state', '$stateParams', 'UsersService', 'Info'];

    function newUserController($rootScope, $state, $stateParams, UsersService, Info) {
        var vm = this;

        //Declare variables
        vm.data = {
            login: null,
            pass: null,
            email: null,
            enable: false
        };

        //Declare functions
        vm.createUser = createUser;

        function createUser() {
            UsersService.registerUser(vm.data)
                .then(function(response) {
                    Info.addStatement('Pomyślnie dodano użytkownika ' + vm.data.login + '. Na podany adres e-mail została wysłana wiadomość umożliwiająca nowemu użytkownikowi uzyskanie dostępu do Portalu.', 'users');
                    $state.go('users');
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }
    }
})();
