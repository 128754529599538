(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('CommuniqueController', CommuniqueController);

    CommuniqueController.$inject = ['Info', 'ShellService'];

    function CommuniqueController(Info, ShellService) {
        var vm = this;

        //Declare variables
        vm.communique = null;


        //Declare functions
        vm.init = init;
        vm.updateCommunique = updateCommunique;
        vm.getCommunique = getCommunique;

        //Init functions
        function init() {
             vm.communique = {
                        dateStart: null,
                        dateEnd: null,
                        line1: null,
                        line2: null
                        };
        }

         function getCommunique() {

                    ShellService.communique1("b").then(function(response){
                      document.getElementById("lane1").value = response.data.result;
                    }).catch(function(response){
                      document.getElementById("lane1").value ="ERROR";
                    });

                     ShellService.communique2("b").then(function(response){
                       document.getElementById("lane2").value = response.data.result;
                     }).catch(function(response){
                       document.getElementById("lane2").value ="ERROR";
                     });

                     ShellService.dateStart().then(function(response){
                       document.getElementById("dateStart").value = response.data.result;
                     }).catch(function(response){
                       document.getElementById("dateStart").value ="ERROR";
                     });

                     ShellService.dateEnd().then(function(response){
                       document.getElementById("dateEnd").value = response.data.result;
                     }).catch(function(response){
                       document.getElementById("dateEnd").value ="ERROR";
                     });

                    }

        function updateCommunique(event) {
        vm.communique.line1 = document.getElementById("lane1").value;
        console.log(document.getElementById("lane1").value);
        vm.communique.line2 = document.getElementById("lane2").value;
        console.log(document.getElementById("lane2").value);
        vm.communique.dateStart = document.getElementById("dateStart").value;
        console.log(document.getElementById("dateStart").value);
        vm.communique.dateEnd =  document.getElementById("dateEnd").value;
        console.log(document.getElementById("dateEnd").value);

                 ShellService.getLine1(vm.communique).then(function(response){
                                     }).catch(function(response){

                                     });
                }

        vm.init();
        vm.getCommunique();
    }
})();
