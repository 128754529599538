(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('infos', ['$state', 'Info', function($state, Info) {
            return {
                restrict: 'E',
                template: "<div class='info comm' ng-repeat='info in infos'></span><span class='info-left comm-left glyphicon glyphicon-info-sign'></span><span ng-bind-html='$sce.trustAsHtml(info)'></span></div>",
                link: function(scope, element, attrs) {
                    //If container is undefined
                    if (Info.infos[$state.current.name] == undefined)
                    //Initialize array
                        Info.infos[$state.current.name] = [];
                    //Give a reference
                    scope.infos = Info.infos[$state.current.name];
                }
            };
        }]);
})();
