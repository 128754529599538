(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('PasswordResetController', PasswordResetController);

    PasswordResetController.$inject = ['$state', '$stateParams', 'PasswordResetService', 'Info'];

    function PasswordResetController($state, $stateParams, PasswordResetService, Info) {
        var vm = this;

        //Variables
        vm.data = {
            login: null,
            email: null,
            newValue: null,
            repeatValue: null,
            token: $stateParams.token
        };

        //Function declarations
        vm.resetPassword = resetPassword;
        vm.confirmResetPassword = confirmResetPassword;

        //Function definitions
        function resetPassword() {
            $('.content').addClass('hidden');
            $('.loader').removeClass('hidden');
            vm.data.captcha = grecaptcha.getResponse();
            if (vm.data.captcha == "") {
                Info.addError("Przed wysłaniem formularza potwierdź swoją tożsamość.");
                $('.content').removeClass('hidden');
                $('.loader').addClass('hidden');
            } else {
                PasswordResetService.resetPassword(vm.data)
                    .then(function(response) {
                        $state.go('passwordReset-Success').finally(function() {
                            $('.content').removeClass('hidden');
                            $('.loader').addClass('hidden');
                        });
                    })
                    .catch(function(response) {
                        switch (response.data.errorCode) {
                        case 205:
                            Info.addError("Podany kod klienta i/lub adres e-mail są nieprawidłowe. Spróbuj ponownie.");
                            $('.content').removeClass('hidden');
                            $('.loader').addClass('hidden');
                            break;
                        case 403:
                        case 408:
                            $state.go('passwordReset-Fail-' + response.data.errorCode).then(function() {
                                    $('.content').removeClass('hidden');
                                    $('.loader').addClass('hidden');
                                });
                            break;
                        default:
                            Info.addErrorCode(response.data.errorCode);
                            $('.content').removeClass('hidden');
                            $('.loader').addClass('hidden');
                        }
                    })
                    .finally(function(response) {
                        grecaptcha.reset();
                    });
            }
        }

        function confirmResetPassword() {
            $('.content').addClass('hidden');
            $('.loader').removeClass('hidden');
            PasswordResetService.confirmResetPassword(vm.data)
                .then(function(response) {
                    $state.go('passwordReset-Confirm-Success');
                    $('.content').removeClass('hidden');
                    $('.loader').addClass('hidden');
                })
                .catch(function(response) {
                    $state.go('passwordReset-Fail-' + response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader').addClass('hidden');
                });
        }
    }
})();
