(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('CommuniqueService', CommuniqueService);

    CommuniqueService.$inject = ['API_URL', '$http'];

    function CommuniqueService(API_URL, $http) {

        return {
            getRoles: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/roles'
                });
            },
            getRole: function(role_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/roles/' + role_id,
                });
            },
            createRole: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/roles',
                    data: data
                });
            },
            updateRole: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/roles',
                    data: data
                });
            },
            deleteRole: function(data) {
                return $http({
                    method: 'DELETE',
                    url: API_URL + 'api/roles/' + data
                });
            },
            getRoleLogs: function(params) {
                 return $http({
                    method: 'GET',
                    url: API_URL + 'api/roles/logs',
                    params: params
                 });
            },
            getRolePermissions: function(role_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/roles/' + role_id + '/permissions',
                });
            },
            updateRolePermissions: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/roles/permissions',
                    data: data
                });
            }
        };
    }
})();
