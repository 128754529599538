(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$sessionStorage', '$window',
        'Auth', 'Principal', 'ENV', 'VERSION', 'Info', '$urlRouter', 'HttpPendingRequestsService'
    ];

    function stateHandler($rootScope, $state, $sessionStorage, $window,
        Auth, Principal, ENV, VERSION, Info, $urlRouter, HttpPendingRequestsService) {

        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.ENV = ENV;
            $rootScope.VERSION = VERSION;

            initializeState();

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function(event, toState, toStateParams, fromState) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;
                $rootScope.fromState = fromState;

                // Redirect to a state with an external URL (http://stackoverflow.com/a/30221248/1098564)
                if (toState.external) {
                    event.preventDefault();
                    $window.open(toState.url, '_self');
                }

                //Abort all pending requests
                HttpPendingRequestsService.cancelAll();

                //Verify if user can access this view
                if (!$rootScope.omit) {
                    Auth.authorize(true).then(function() {
                        if (Principal.isAuthenticated()) {
                            if (!$state.isApplication(toState) && !$state.isAccessible(toState)) {
                                $state.go('documents');
                                return;
                            } else if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                                $state.go('403');
                                return;
                            }
                        } else {
                            if ($state.isApplication(toState)) {
                                $rootScope.afterAuth = toState.name;
                                $state.go('401');
                                return;
                            }
                        }
                        $rootScope.omit = true;
                        $state.go(toState, toStateParams);
                    });
                    event.preventDefault();
                } else {
                    $rootScope.omit = false;
                }
                //Hide sidebar if not going to app state
                if (!$state.isApplication(toState)) {
                    $rootScope.sidebar = false;
                    var toggle = $('.navbar-toggle');
                    $('html').css("transition", "none");
                    $('html').removeClass('nav-open');
                    setTimeout(function() {
                        toggle.removeClass('toggled');
                        $('html').css("transition", "all 0.3s ease-in-out");
                    }, 430);
                }
            });

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
                var titleKey = 'edistradaIdealeasingPortal';

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                $window.document.title = titleKey;

                //Clear all info from exited state
                Info.clear(fromState.name);
            });

            $rootScope.$on('$stateChangeError', function(event) {
                event.preventDefault();
                $state.go('404');
            });

            $rootScope.$on('$destroy', function() {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
                if (angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null) {
                    stateChangeSuccess();
                }
            });
        }

        function initializeState() {
            $state.application = [];
            $state.accessible = [];

            $state.getStateChildren = function(states, rootState, children) {
                children.push(rootState);

                for (var state in states) {
                    if (states[state].parent == rootState.name)
                        $state.getStateChildren(states, states[state], children);
                }
            };

            $state.addApplication = function(state) {
                if (typeof state == 'string')
                    state = $state.get(state);
                $state.getStateChildren($state.get(), state, $state.application);
            };

            $state.isApplication = function(state) {
                if (typeof state == 'string')
                    state = $state.get(state);
                for (var stateIndex in $state.application)
                    if ($state.application[stateIndex].name == state.name)
                        return true;
                return false;
            };

            $state.addAccessible = function(state) {
                if (typeof state == 'string')
                    state = $state.get(state);
                $state.getStateChildren($state.get(), state, $state.accessible);
            };

            $state.isAccessible = function(state) {
                if (typeof state == 'string')
                    state = $state.get(state);
                for (var stateIndex in $state.accessible)
                    if ($state.accessible[stateIndex].name == state.name)
                        return true;
                return false;
            };

            $state.addApplication($state.get('application'));
            $state.addAccessible($state.get('emailChange'));
            $state.addAccessible($state.get('error'));
            $state.addAccessible($state.get('userActivate'));
            $state.addAccessible($state.get('appRegulations'));

        }
    }
})();
