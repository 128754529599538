(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('subjects', function() {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    var subjects = scope.agreement.subjects;
                    var tooltip = "<div style='text-align:left;'>";
                    for (var subject in subjects) {
                        tooltip += '<li style="font-size:10pt;font-weight:normal;margin:5px;">' + subjects[subject] + '</li>';
                    }
                    tooltip += "</div>";

                    element.tooltip({
                        placement: function() {
                            if (window.innerHeight / 2 - $(element)[0].getBoundingClientRect().top > 0)
                                return 'bottom';
                            else
                                return 'top';
                        },
                        html: true,
                        title: tooltip,
                        container: 'body'
                    });

                    $(element).hover(function() {
                        $(this).tooltip('show');
                    },
                        function() {

                            $(this).tooltip('hide');
                        });
                }
            };
        });
})();
