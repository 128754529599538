(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('amount', ['Utils', function(Utils) {
            return {
                require: 'ngModel',
                restrict: 'E',
                replace: true,
                template: "<input type='text' title='' data-toggle='tooltip' data-trigger='focus' data-html='true'>",
                link: function(scope, element, attributes, control) {

                    //MODEL -> VIEW
                    control.$formatters.push(function(value) {
                        if (Utils.isNotAmount(value))
                            return null;
                        return value.toString().replace('.', ',');
                    });

                    //VIEW -> MODEL
                    control.$parsers.push(function(value) {
                        if (Utils.isNotAmount(value))
                            return value;
                        return parseFloat(value.replace(',', '.'));
                    });

                    //Validate minimum
                    control.$validators.min = jQuery.proxy(function(value) {
                        var minimum = null;
                        if (!Utils.isNotAmount($(this).attr('data-min')))
                            return (control.$isEmpty(value) || (value >= $(this).attr('data-min')));
                        else
                            return true;
                    }, element);

                    //Validate maximum
                    control.$validators.max = jQuery.proxy(function(value) {
                        if (!Utils.isNotAmount($(this).attr('data-max')))
                            return (control.$isEmpty(value) || (value <= $(this).attr('data-max')));
                        else
                            return true;
                    }, element);

                    //Validate amount
                    control.$validators.amount = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[+-]?\d+([,.]\d{1,2})?$/.test(value.toString()));
                    }, element);

                    //Live validation tooltip
                    $(element).on('keyup change', function(event) {
                        var tooltip = '';
                        if (control.$error.min)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj liczbę większą lub równą " + $(this).attr('data-min') + ".</p>";
                        if (control.$error.max)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj liczbę mniejszą lub równą " + $(this).attr('data-max') + ".</p>";
                        if (control.$error.amount)
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>Podaj prawidłową kwotę netto, np.: '12,22'.</p>";

                        if (control.$valid) {
                            $(this).attr('data-original-title', tooltip);
                            $(this).tooltip('hide');
                        } else if (tooltip != $(this).attr('data-original-title')) {
                            $(this).attr('data-original-title', tooltip);
                            if ($(this)[0] == document.activeElement)
                                $(this).tooltip('show');
                        }
                    });
                }
            };
        }]);

})();
