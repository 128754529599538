(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('faq', {
            parent: 'application',
            url: '/faq',
			            params :{
			},
            data: {
                authorities: [],
                pageTitle: 'Pytania i odpowiedzi - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/faq/faq.html'
                }
            }
        });
    }
})();
