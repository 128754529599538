(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('agreements', {
            parent: 'application',
            url: '/agreements?login&agreement_nr&agreement_date_from&agreement_date_to&end_plan_date_from&end_plan_date_to&subject&registration_number&status',
            params: {
                login: null,
                agreement_nr: null,
                agreement_date_from: null,
                agreement_date_to: null,
                end_plan_date_from: null,
                end_plan_date_to: null,
                subject: null,
                registration_number: null,
                status: null
            },
            data: {
                authorities: [],
                pageTitle: 'Umowy - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/agreements/agreements.html',
                    controller: 'AgreementsController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
