(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('documents', {
            parent: 'application',
            url: '/documents?login&agreement_nr&document_nr&date_issue_from&date_issue_to&document_types&total_netto_from&total_netto_to&currency&date_document_open_from&date_document_open_to&date_payment_from&date_payment_to',
            params: {
                login: null,
                agreement_nr: null,
                document_nr: null,
                date_issue_from: null,
                date_issue_to: null,
                document_types: [],
                total_netto_from: null,
                total_netto_to: null,
                currency: null,
                date_document_open_from: null,
                date_document_open_to: null,
                date_payment_from: null,
                date_payment_to: null
            },
            data: {
                pageTitle: 'Faktury i noty - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/documents/documents.html',
                    controller: 'DocumentsController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
