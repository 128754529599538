(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('RegisterController', RegisterController);

    RegisterController.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils'];

    function RegisterController($state, $stateParams, $location, RegisterService, Info, Utils) {
        var vm = this;

        vm.location = $location;

        vm.data = {
            login: null,
            document_nr: null,
            total_netto: null,
            email: null,
            password: null,
            telephone_number: null,
            agreement1: false,
            agreement2: false,
            agreement3: false,
            agreement4: false,
            agreement5: false,
            agreement6: false
        };

        vm.activation = {
            startPassword: null,
            password: null,
            newRepeat: null,
            token: null
        };

        //Declare functions
        vm.init = init;
        vm.stepValidity = stepValidity;
        vm.proceed = proceed;
        vm.back = back;
        vm.register = register;
        vm.activate = activate;
        vm.quickActivate = quickActivate;
        vm.userActivate = userActivate;

        function init() {
            if ($state.current.name == 'activate' || $state.current.name == 'quickActivate' || $state.current.name == 'userActivate') {
                //Copy URL parameters to data
                angular.copy($stateParams, vm.activation);
            }
        }

        function stepValidity() {
            var state = $state.current.name;
            //If current state is not first, and step before has not been properly filled
            while (state.slice(-1) > 1 && !vm['isFilledStep' + (state.slice(-1) - 1)]()) {
                //Change state to one before
                state = state.slice(0, -1) + (state.slice(-1) - 1);
            }
            //If not all steps before current were filled properly
            if (state != $state.current.name) {
                //Change to last properly filled state
                $state.go(state);
                return false;
            } //If all steps were filled properly
            else {
                return true;
            }
        }

        function proceed(event) {
            if (vm.stepValidity()) {
                switch ($state.$current.self.url) {
                case '/1':
                    $state.go('register2');
                    break;
                case '/2':
                    $state.go('register3');
                    break;
                case '/3':
                    vm.register(event);
                case '/regulations':
                    $state.go('register3');
                }
            }
        }

        function back() {
            switch ($state.$current.self.url) {
            case '/1':
                $state.go('login');
                break;
            case '/2':
                $state.go('register1');
                break;
            case '/3':
                $state.go('register2');
                break;
            }
        }

        function step1() {
            RegisterService.step1(vm.data)
                //If successful
                .then(function(response) {
                    //Change URL to match the new query
                    $state.go('register2');
                })
                //If there were errors
                .catch(function(response) {
                    if ([205, 206].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register1');
                        $state.go('register1');
                    } else {
                        Info.addErrorCode(response.data.errorCode, 'register-fail');
                        $state.go('register-fail-' + response.data.errorCode);
                    }
                });
        }

        function register(event) {
            $('.content').addClass('hidden');
            $('.loader.registrator').removeClass('hidden');
            //Notify the user of sending and disable the button
            event.target.innerText = "Wysyłanie...";
            event.target.disabled = true;

            //Register with given data
            RegisterService.register(vm.data)
                //If successful
                .then(function(response) {
                    //Change URL to match the new query
                    $state.go('register-finish');
                    $('.content').removeClass('hidden');
                    $('.loader.registrator').addClass('hidden');
                })
                //If there were errors
                .catch(function(response) {
                    if ([202].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register2');
                        $state.go('register2');
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    } else if ([205, 206].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register1');
                        $state.go('register1');
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    } else {
                        Info.addErrorCode(response.data.errorCode, 'register-fail');
                        $state.go('register-fail-' + response.data.errorCode);
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    }
                    //Enable the button and return to former text
                    event.target.innerText = "Wyślij";
                    event.target.disabled = false;
                    $('.content').removeClass('hidden');
                    $('.loader.registrator').addClass('hidden');
                });
        }

        function activate() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            RegisterService.activate(vm.activation)
                .then(function() {
                    Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą kodu klienta oraz nowego hasła.', 'login');
                    $state.go('login');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }

        function quickActivate() {
                    $('.content').addClass('hidden');
                    $('.loader.activator').removeClass('hidden');
                    var activationToSend={};
                    angular.copy(vm.activation, activationToSend);
                    delete activationToSend["newRepeat"]
                    RegisterService.quickActivate(activationToSend)
                        .then(function() {
                            Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą kodu klienta oraz nowego hasła.', 'login');
                            $state.go('login');
                            $('.content').removeClass('hidden');
                            $('.loader.activator').addClass('hidden');
                        }).catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                            $('.content').removeClass('hidden');
                            $('.loader.activator').addClass('hidden');
                        });
                }

        function userActivate() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            RegisterService.userActivate(vm.activation)
                .then(function() {
                    Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą loginu oraz nowego hasła.', 'login');
                    $state.go('login');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }

        init();
    }
})();
