(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('userActivate', {
            parent: 'register',
            url: '/activate/user',
            data: {
                pageTitle: 'Aktywacja konta klienta - VB Leasing'
            },
            views: {
                'step@register': {
                    templateUrl: 'app/views/userActivate/user-activate.html',
                    controller: 'ActivateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                registration: function($q, $timeout, $state, $stateParams, Utils, RegisterService) {

                    var deferred = $q.defer();

                    // $timeout is an example; it also can be an xhr request or any other async function
                    $timeout(function() {
                        if (Utils.isEmpty($stateParams.token)) {
                            $state.go('login');
                            deferred.reject();
                        } else {
                            RegisterService.getRegistration($stateParams)
                                .then(function(response) {
                                    if (response.data.result != null) {
                                        deferred.resolve(response.data.result);
                                    } else {
                                        $state.go('login');
                                        deferred.reject();
                                    }
                                })
                                .catch(function(response) {
                                    $state.go('register-fail-' + response.data.errorCode);
                                    deferred.reject();
                                });
                        }
                    });

                    return deferred.promise;
                }
            }
        });
    }
})();
