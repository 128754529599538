(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('PasswordController', PasswordController);

    PasswordController.$inject = ['$state', '$stateParams', 'PasswordService'];

    function PasswordController ($state, $stateParams, PasswordService) {
		        var vm = this;		
		
		
    }
})();
