(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('newRole', {
            parent: 'application',
            url: '/role/new',
            params: {
                role_id: null
            },
            data: {
                authorities: ['ROLE_RESOURCE'],
                pageTitle: 'Nowa rola - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/newRole/newRole.html',
                    controller: 'newRoleController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
