(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('pass', ['Utils', function(Utils) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='password' title='' autocomplete='new-password' data-toggle='tooltip' data-trigger='focus' data-html='true'>",
                link: function(scope, element, attributes, control) {

                    //Validate minimum
                    control.$validators.max = jQuery.proxy(function(value) {
                        var maximum = 256;
                        if (!Utils.isEmpty($(this).attr('data-max')))
                            maximum = $(this).attr('data-max');
                        return (control.$isEmpty(value) || (value.length <= maximum));
                    }, element);

                    //Validate password
                    control.$validators.password = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /(?=.*[~`!@#$%^&*()_+-={}\[\]|:";'<>?,\\/.0-9]+.*)(?=.*[a-z]+.*)(?=.*[A-Z]+.*)[0-9a-zA-Z~`!@#$%^&*()_+-={}\[\]|:";'<>?,\\/.]{8,}/.test(value));
                    }, element);

                    if (element.attr('data-old-password') != undefined) {
                        //Validate old password
                        control.$validators.oldPassword = jQuery.proxy(function(value) {
                            angular.element('input[data-new-password]').data('$ngModelController').$validate();
                            angular.element('input[data-new-password]').trigger('change');
                            return true;
                        }, element);
                    }

                    if (element.attr('data-new-password') != undefined) {
                        //Validate new password
                        control.$validators.newPassword = jQuery.proxy(function(value) {
                            angular.element('input[data-repeat-password]').data('$ngModelController').$validate();
                            angular.element('input[data-repeat-password]').trigger('change');
                            return (control.$isEmpty(value) || $('input[data-old-password]').val() != value);
                        }, element);
                    }

                    if (element.attr('data-repeat-password') != undefined) {
                        //Validate repeat password
                        control.$validators.repeatPassword = jQuery.proxy(function(value) {
                            return (control.$isEmpty(value) || $('input[data-new-password]').val() == value);
                        }, element);
                    }

                    //Live validation tooltip
                    $(element).on('keyup change', function(event) {
                        var tooltip = '';
                        if (control.$error.max)
                            tooltip += "<p>Hasło może zawierać maksymalnie " + ($(this).attr('data-max') || 256) + " znaków..</p>";
                        if (control.$error.password)
                            tooltip += "<p>Hasło powinno zawierać przynajmniej 8 znaków, w tym przynajmniej jedną małą i wielką literę oraz cyfrę lub znak specjalny.</p>";
                        if (control.$error.newPassword)
                            tooltip += "<p>Nowe hasło jest identyczne jak dotychczasowe.</p>";
                        if (control.$error.repeatPassword)
                            tooltip += "<p>Podane nowe hasła nie są identyczne.</p>";

                        if (control.$valid || this.classList.contains('ng-empty')) {
                            $(this).attr('data-original-title', tooltip);
                            $(this).tooltip('hide');
                        } else if (tooltip != $(this).attr('data-original-title')) {
                            $(this).attr('data-original-title', tooltip);
                            if ($(this)[0] == document.activeElement)
                                $(this).tooltip('show');
                        }
                    });
                }
            };
        }]);
})();
