(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('site', {
                abstract: true
            })
            .state('application', {
                parent: 'site',
                abstract: true,
                resolve: {
                    authorize: function(Auth) {
                        return Auth.authorize();
                    }

                },
                url: '/app',
                views: {
                    'index@': {
                        templateUrl: 'app/layouts/application/application.html'
                    },
                    'shell@application': {
                        templateUrl: 'app/layouts/shell/shell.html',
                        controller: 'ShellController',
                        controllerAs: 'vm'
                    },
                    'sidebar@application': {
                        templateUrl: 'app/layouts/sidebar/sidebar.html',
                        controller: 'SidebarController',
                        controllerAs: 'vm'
                    },
                    'footer@application': {
                        templateUrl: 'app/layouts/footer/footer.html'
                    }
                }
            });
    }
})();
