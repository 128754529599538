(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('Register1Controller', Register1Controller);

    Register1Controller.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils', 'registration'];

    function Register1Controller($state, $stateParams, $location, RegisterService, Info, Utils, registration) {
        var vm = this;

        vm.data = registration ? registration : {
            login: null,
            document_nr: null,
            total_netto: null
        };

        if (registration == null) {
            RegisterService.registration = {};
        } else
            angular.merge(vm.data, RegisterService.registration);

        //Declare functions
        vm.proceed = proceed;

        function proceed(event) {
            //Notify the user of sending and disable the button
            event.target.innerText = "Weryfikacja...";
            event.target.disabled = true;
            RegisterService.step1(vm.data)
                //If successful
                .then(function(response) {
                    //Change URL to match the new query
                    $state.go('register2', { token: response.data.result });
                })
                //If there were errors
                .catch(function(response) {
                    if ([205, 206].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register1');
                    } else if ([208].indexOf(response.data.errorCode) != -1) {

                    } else {
                        Info.addErrorCode(response.data.errorCode, 'register-fail');
                        $state.go('register-fail-' + response.data.errorCode);
                    }
                })
                .finally(function() {
                    event.target.innerText = "Dalej";
                    event.target.disabled = false;
                });
        }
    }
})();
