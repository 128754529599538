(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('email', ['Utils', function(Utils) {

            return {
                require: 'ngModel',
                restrict: 'E',
                replace: 'true',
                template: "<input type='text' title='' data-toggle='tooltip' data-trigger='focus' data-html='true'>",
                link: function(scope, element, attributes, control) {

                    //Validate minimum
                    control.$validators.max = jQuery.proxy(function(value) {
                        var maximum = 256;
                        if (!Utils.isEmpty($(this).attr('data-max')))
                            maximum = $(this).attr('data-max');
                        return (control.$isEmpty(value) || (value.length <= maximum));
                    }, element);

                    //Validate email
                    control.$validators.email = jQuery.proxy(function(value) {
                        return (control.$isEmpty(value) || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value));
                    }, element);


                    if (element.attr('data-old-email') != undefined) {
                        //Validate old email
                        control.$validators.oldEmail = jQuery.proxy(function(value) {
                            angular.element('input[data-new-email]').data('$ngModelController').$validate();
                            angular.element('input[data-new-email]').trigger('change');
                            return true;
                        }, element);
                    }

                    if (element.attr('data-new-email') != undefined) {
                        //Validate new email
                        control.$validators.newEmail = jQuery.proxy(function(value) {
                            angular.element('input[data-repeat-email]').data('$ngModelController').$validate();
                            angular.element('input[data-repeat-email]').trigger('change');
                            return (control.$isEmpty(value) || $('input[data-old-email]').val() != value);
                        }, element);
                    }

                    if (element.attr('data-repeat-email') != undefined) {
                        //Validate repeat email
                        control.$validators.repeatEmail = jQuery.proxy(function(value) {
                            return (control.$isEmpty(value) || $('input[data-new-email]').val() == value);
                        }, element);
                    }

                    //Live validation tooltip
                    $(element).on('keyup change', function(event) {
                        var tooltip = '';
                        if (control.$error.max)
                            tooltip += "<p>Adres e-mail może zawierać maksymalnie " + ($(this).attr('data-max') || 256) + " znaków..</p>";
                        if (control.$error.email)
                            tooltip += "<p>Wprowadź poprawny adres e-mail.</p>";
                        if (control.$error.newEmail)
                            tooltip += "<p>Nowy adres e-mail jest identyczny jak dotychczasowy.</p>";
                        if (control.$error.repeatEmail)
                            tooltip += "<p>Podane adresy e-mail nie są identyczne.</p>";

                        if (control.$valid || this.classList.contains('ng-empty')) {
                            $(this).attr('data-original-title', tooltip);
                            $(this).tooltip('hide');
                        } else if (tooltip != $(this).attr('data-original-title')) {
                            $(this).attr('data-original-title', tooltip);
                            if ($(this)[0] == document.activeElement)
                                $(this).tooltip('show');
                        }
                    });
                }
            };
        }]);
})();
