(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('authExpiredInterceptor', authExpiredInterceptor);


    authExpiredInterceptor.$inject = ['$rootScope', '$q', '$injector', '$document', '$sessionStorage', 'API_URL'];

    function authExpiredInterceptor($rootScope, $q, $injector, $document, $sessionStorage, API_URL) {
        var service = {
            responseError: responseError
        };

        return service;

        function responseError(response) {
            // If we have an unauthorized request we redirect to the login page
            // Don't do this check on the account API to avoid infinite loop
            if (response.status === 401 && angular.isDefined(response.data.path) && response.data.path.indexOf('/api/session') === -1) {
                var Auth = $injector.get('Auth');
                var to = $rootScope.toState;
                var params = $rootScope.toStateParams;
                Auth.logout();
                if (to.name !== 'accessdenied') {
                    $rootScope.afterAuth = to.name;
                    $rootScope.afterAuthParams = params;
                    /* $sessionStorage.previousStateName = to.name;
                     $sessionStorage.previousStateParams = params;*/
                }
                //                var LoginService = $injector.get('LoginService');
                //                LoginService.open();
                if ($rootScope.fromState)
                    $injector.get('$state').go('401');
                else
                    $injector.get('$state').go('login');
            } else if /*the CSRF token expired, then try to get a new CSRF token and retry the old request*/ (response.status === 403 && response.config.method !== 'GET' && getCSRF() === '') {
                var $http = $injector.get('$http');
                return $http.get(API_URL + 'api/welcome').finally(function() {
                    return afterCSRFRenewed(response);
                });
            }
            return $q.reject(response);
        }

        /*Get CSRF token*/
        function getCSRF() {
            var doc = $document[0];
            if (doc) {
                var name = 'CSRF-TOKEN=';
                var ca = doc.cookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) === ' ') { c = c.substring(1); }

                    if (c.indexOf(name) !== -1) {
                        return c.substring(name.length, c.length);
                    }
                }
            }
            return '';
        }

        function afterCSRFRenewed(oldResponse) {
            //If CSRF token was renewed
            if (getCSRF() !== '') {
                // Retry the old request
                var $http = $injector.get('$http');
                //And return its response
                return $http(oldResponse.config);
            } else {
                // Unlikely to get here but reject with the old response any way and avoid infinite loop
                return $q.reject(oldResponse);
            }
        }
    }
})();
