(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('tooltip', function() {
            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    element.attr('data-html', true);
                    $(element).hover(function() {
                        // on mouseenter
                        $(element).tooltip('show');
                    }, function() {
                        // on mouseleave
                        $(element).tooltip('hide');
                    });
                }
            };
        });
})();
