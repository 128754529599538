(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('UserController', UserController);

    UserController.$inject = ['$state', '$stateParams', 'UsersService', 'RolesService', 'DateUtils', 'Info', 'PasswordResetService', 'Principal'];

    function UserController($state, $stateParams, UsersService, RolesService, DateUtils, Info, PasswordResetService, Principal) {
        var vm = this;

        //Declare variables
        vm.data = {
            user: null,
            roles: null,
            logs: null
        };

        vm.filter = {
            user_id: $stateParams.user_id,
            ts_from: null,
            ts_to: null
        };

        vm.warnings = [];

        //Declare functions
        vm.init = init;
        vm.now = now;
        vm.getUser = getUser;
        vm.getUserRoles = getUserRoles;
        vm.getUserLogs = getUserLogs;
        vm.getRoles = getRoles;
        vm.updateUserRoles = updateUserRoles;
        vm.deleteUser = deleteUser;
        vm.resetPassword = resetPassword;
        vm.unlock = unlock;
        vm.generateUpdateObject = generateUpdateObject;
        vm.Date = DateUtils.convertDateTimeFromServer;
        vm.toggleActivation = toggleActivation;
        vm.resetFilters = resetFilters;
        vm.getLogQueryObject = getLogQueryObject;

        //Init functions
        function init() {
            //Get user if specified in state parameters
            if ($stateParams.user_id != null) {
                vm.getUser();
            }
        }

        function getUser() {
            //Get role JSON
            UsersService.getUser($stateParams.user_id)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.user = response.data.result;
                    vm.getRoles();
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getUserRoles() {
            //Get role JSON
            UsersService.getUserRoles($stateParams.user_id)
                //If successful
                .then(function(response) {
                    for (var userRole in response.data.result.roles) {
                        for (var role in vm.data.roles) {
                            if (vm.data.roles[role].role_id == response.data.result.roles[userRole].role_id) {
                                vm.data.roles[role].checked = true;
                            }
                        }
                    }
                    vm.getUserLogs();
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getUserLogs() {
            Principal.hasAuthority('LOGS_EMPLOYEE_RESOURCE').then(function(response) {
                if (response) {
                    //Get role JSON
                    UsersService.getUserLogs(vm.getLogQueryObject())
                        //If successful
                        .then(function(response) {
                            vm.data.logs = response.data.result.logs;
                            $state.transitionTo('user', vm.getLogQueryObject(), {
                                location: true,
                                inherit: true,
                                relative: $state.$current,
                                notify: false
                            });
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        });
                }
            });
        }

        function getRoles() {
            Principal.hasAuthority('ROLE_RESOURCE').then(function(response) {
                if (response) {
                    //Get documents JSON by new query
                    RolesService.getRoles()
                        //If successful
                        .then(function(response) {
                            //Set data to controller data
                            vm.data.roles = response.data.result.roles;
                            vm.getUserRoles();
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        });
                }
            });


        }

        function updateUserRoles() {
            //Get role JSON
            UsersService.updateUserRoles(vm.generateUpdateObject())
                //If successful
                .then(function(response) {
                    Info.addStatement('Pomyślnie zaktualizowano role użytkownika ' + vm.data.user.login + '.');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function generateUpdateObject() {
            var role_ids = [];
            for (var role in vm.data.roles) {
                if (vm.data.roles[role].checked)
                    role_ids.push(vm.data.roles[role].role_id);
            }
            return {
                user_id: $stateParams.user_id,
                role_ids: role_ids
            };
        }

        function deleteUser() {
            //Get role JSON
            UsersService.deleteUser(vm.data.user.user_id)
                //If successful
                .then(function(response) {
                    Info.addStatement('Pomyślnie usunięto użytkownika ' + vm.data.user.login + '.', 'users');
                    $state.go('users');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function unlock(event) {
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Odblokowywanie...";
                event.target.disabled = true;
            }
            UsersService.unlockUser(vm.data.user.login)
                .then(function(response) {
                    Info.addStatement("Odblokowano konto użytkownika.");
                    vm.data.user.account_blocked = false;
                    vm.data.user.invalid_login_counter = 0;
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Odblokuj konto";
                        event.target.disabled = false;
                    }
                });
        }

        function toggleActivation() {
            //Get role JSON
            UsersService.updateUser({
                user_id: vm.data.user.user_id,
                enable: !vm.data.user.enable
            })
                //If successful
                .then(function(response) {
                    vm.data.user.enable = !vm.data.user.enable;
                    if (vm.data.user.enable) {
                        Info.addStatement('Pomyślnie aktywowano konto użytkownika ' + vm.data.user.login + '.');
                    } else {
                        Info.addStatement('Pomyślnie deaktywowano konto użytkownika ' + vm.data.user.login + '.');
                    }
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function resetPassword(event) {
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Resetowanie...";
                event.target.disabled = true;
            }
            PasswordResetService.resetPassword({
                login: vm.data.user.login,
                email: vm.data.user.email
            })
                .then(function(response) {
                    Info.addStatement("Na adres e-mail pracownika została wysłana wiadomość umożliwiająca nadanie nowego hasła.");
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Zresetuj hasło";
                        event.target.disabled = false;
                    }
                });
        }

        function getLogQueryObject() {
            var filter = jQuery.extend(true, {}, vm.filter);
            filter.relative_id = filter.user_id;
            filter.maker_id = filter.user_id;
            return filter;
        }

        function resetFilters() {
            vm.filter = {
                user_id: $stateParams.user_id,
                ts_from: null,
                ts_to: null
            };
        }

        function getDate(date) {
            var result = new Date(0);
            result.setSeconds(0);
            result.setMinutes(0);
            result.setHours(0);
            result.setDate(date.split('.')[0]);
            result.setMonth(date.split('.')[1] - 1);
            result.setYear(date.split('.')[2]);
            return result.getTime();
        }

        function now() {
            return new Date().getTime();
        }

        init();
    }
})();
