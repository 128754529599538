(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('UsersService', UsersService);

    UsersService.$inject = ['API_URL', '$http'];

    function UsersService(API_URL, $http) {

        return {
            'getUsers': function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/users',
                    params: params
                });
            },
            'getUser': function(user_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/users/' + user_id,
                });
            },
            'createUser': function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/users',
                    data: data
                });
            },
            'updateUser': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/users',
                    data: data
                });
            },
            'deleteUser': function(data) {
                return $http({
                    method: 'DELETE',
                    url: API_URL + 'api/users/' + data
                });
            },
            'getUserRoles': function(user_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/users/' + user_id + '/roles',
                });
            },
            'updateUserRoles': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/users/roles',
                    data: data
                });
            },
            'getUserPermissions': function(user_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/users/' + user_id + '/permissions',
                });
            },
            'getUserLogs': function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/users/logs',
                    params: params
                });
            },
            'registerUser': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register/user',
                    data: data
                });
            },
            unlockUser: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/users/unlock',
                    data: data
                });
            }
        };
    }
})();
