(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('Register2Controller', Register2Controller);

    Register2Controller.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils', 'registration'];

    function Register2Controller($state, $stateParams, $location, RegisterService, Info, Utils, registration) {
        var vm = this;

        vm.data = registration ? registration : {
            email: null,
            newPassword: null,
            password: null,
            telephone_number: null
        };

        angular.merge(vm.data, RegisterService.registration);

        //Declare functions
        vm.proceed = proceed;
        vm.back = back;

        function proceed(event) {
            //Notify the user of sending and disable the button
            event.target.innerText = "Weryfikacja...";
            event.target.disabled = true;
            RegisterService.step2(vm.data)
                //If successful
                .then(function(response) {
                    //Change URL to match the new query
                    $state.go('register3');
                })
                //If there were errors
                .catch(function(response) {
                    if ([205, 206].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register2');
                    } else {
                        Info.addErrorCode(response.data.errorCode, 'register-fail');
                        $state.go('register-fail-' + response.data.errorCode);
                    }
                }).finally(function() {
                    event.target.innerText = "Dalej";
                    event.target.disabled = false;
                });
        }

        function back() {
            $state.go('register1');
            RegisterService.persist(vm.data);
        }
    }
})();
