(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('ClientController', ClientController);

    ClientController.$inject = ['$state', '$stateParams', 'ClientsService', 'DateUtils', 'Info', 'PasswordResetService', 'Principal', '$scope', 'Utils'];

    function ClientController($state, $stateParams, ClientsService, DateUtils, Info, PasswordResetService, Principal, $scope, Utils) {
        var vm = this;

        vm.data = null;

        //Declare functions
        vm.init = init;
        vm.previous = previous;
        vm.next = next;
        vm.page = page;
        vm.setClient = setClient;
        vm.getClient = getClients;
        vm.getClientLogs = getClientLogs;
        vm.resetPassword = resetPassword;
        vm.unlock = unlock;

        //Declare utils
        vm.validateFilter = validateFilter;
        vm.getLogQueryObject = getLogQueryObject;
        vm.Date = DateUtils.convertDateTimeFromServer;
        vm.isFilterFilled = isFilterFilled;
        vm.getValue = getValue;
        vm.resetFilters = resetFilters;
        vm.now = now;
        vm.getNumber = getNumber;
        vm.pipeRegex = Utils.getPipeRegex();

        //Init functions
        function init() {
            //Init filter
            vm.filter = {
                current: {
                    login: null,
                    email: null

                },
                query: {
                    login: null,
                    email: null
                },
                log: {
                    client_id: null,
                    ts_from: null,
                    ts_to: null
                }
            };

            //Init data
            vm.data = {
                offset: 0,
                client: null,
                result: {}
            };

            //Copy URL parameters to current filter
            angular.copy($stateParams, vm.filter.query);

            //If the filter is filled
            if (isFilterFilled(vm.filter.query)) {
                //Get client from server
                getClients();
            } else {
                Info.addInfo("Proszę uzupełnić przynajmniej jedno z pól: KOD KLIENTA lub E-MAIL.");
            }
        }

        function previous() {
            vm.data.offset--;
            if (vm.data.offset < parseInt(vm.data.pageStart) + 3)
                vm.data.pageStart = Math.max(0, vm.data.offset - 2);
            if (vm.data.offset > parseInt(vm.data.pageStart) + 7)
                vm.data.pageStart = Math.min(vm.data.result.pageCount - 10, vm.data.offset - 7);
            getClients();
        }

        function next() {
            vm.data.offset++;
            if (vm.data.offset < parseInt(vm.data.pageStart) + 3)
                vm.data.pageStart = Math.max(0, vm.data.offset - 2);
            if (vm.data.offset > parseInt(vm.data.pageStart) + 7)
                vm.data.pageStart = Math.min(vm.data.result.pageCount - 10, vm.data.offset - 7);
            getClients();
        }

        function page(index) {
            vm.data.offset = index;
            if (vm.data.result.pageCount > 10) {
                if (vm.data.offset < parseInt(vm.data.pageStart) + 3)
                    vm.data.pageStart = Math.max(0, vm.data.offset - 2);
                if (vm.data.offset > parseInt(vm.data.pageStart) + 7)
                    vm.data.pageStart = Math.min(vm.data.result.pageCount - 10, vm.data.offset - 7);
                if (vm.data.result.pageCount - 10 < parseInt(vm.data.pageStart))
                    vm.data.pageStart = Math.min(vm.data.result.pageCount - 10, vm.data.offset - 7);
            }
            getClients();
        }

        function setClient($event, client) {
            Info.clear();
            Info.setAction("Pobieranie klienta...");
            //Get users JSON by new query
            ClientsService.getClient(client)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.client = response.data.result;
                    if (vm.data.client != null)
                        getClientLogs();
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    Info.clearAction();
                });
        }

        function getClients(event) {
            Info.clear();
            Info.setAction("Wyszukiwanie klientów...");
            vm.data.client = null;
            //If event is not undefined
            if (event != undefined) {
                vm.data.pageStart = 0;
                vm.data.offset = 0;
                //Notify the user of searching and disable the button
                event.target.innerText = "Wyszukiwanie...";
                event.target.disabled = true;
            }
            //Validate the filter
            vm.validateFilter(vm.filter.query);
            //Get users JSON by new query
            ClientsService.getClients(vm.filter.query, vm.data.offset)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.result = response.data.result;
                    //Update current filter without binding
                    angular.copy(vm.filter.query, vm.filter.current);

                    //Change URL to match the new query
                    $state.transitionTo('client', vm.filter.current, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });

                    if (vm.data.result.entries == null || vm.data.result.entries.clients == null || vm.data.result.entries.clients.length == 0) {
                        Info.addStatement("Brak klientów spełniających podane kryteria.");
                        vm.data.client = null;
                    }
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Wyszukaj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function getClientLogs() {
            Principal.hasAuthority('LOGS_CLIENT_RESOURCE').then(function(response) {
                if (response) {
                    Info.setAction("Pobieranie historii klienta...");
                    //Get users JSON by new query
                    ClientsService.getClientLogs(vm.getLogQueryObject())
                        //If successful
                        .then(function(response) {
                            //Set data to controller data
                            vm.data.logs = response.data.result.logs;
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        })
                        .finally(function() {
                            Info.clearAction();
                            $('html, body').animate({
                                scrollTop: Math.min($('body').height() - window.innerHeight, $('#clientInfo').offset().top - 60)
                            }, 1000, 'swing');
                        });
                }
            });
        }

        function resetPassword(event) {
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Resetowanie...";
                event.target.disabled = true;
            }
            PasswordResetService.resetPassword({
                    login: vm.data.client.login,
                    email: vm.data.client.email
                })
                .then(function(response) {
                    Info.addStatement("Na adres e-mail klienta została wysłana wiadomość umożliwiająca nadanie nowego hasła.");
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Zresetuj hasło";
                        event.target.disabled = false;
                    }
                });
        }

        function unlock(event) {
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Odblokowywanie...";
                event.target.disabled = true;
            }
            ClientsService.unlockClient(vm.data.client.login)
                .then(function(response) {
                    Info.addStatement("Odblokowano konto klienta.");
                    vm.data.client.account_blocked = false;
                    vm.data.client.invalid_login_counter = 0;
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Odblokuj konto";
                        event.target.disabled = false;
                    }
                });
        }

        function validateFilter(filter) {
            for (var key in filter) {
                if (!isFieldFilled(filter[key])) {
                    filter[key] = null;
                }
            }
        }

        function getLogQueryObject() {
            validateFilter(vm.filter.log);
            var filter = jQuery.extend(true, {}, vm.filter.log);
            filter.maker_id = vm.data.client.client_id;
            filter.relative_id = vm.data.client.client_id;
            return filter;
        }

        function isFilterFilled(filter) {
            //Check all keys
            for (var key in filter) {
                //If the value matched by key is not empty return true
                if (isFieldFilled(filter[key])) {
                    return true;
                }
            }
            //If there are no values in the filter return false
            return false;
        }

        function isFieldFilled(field) {
            if (field != null && field != '')
                return true;
            else
                return false;
        }

        function getValue(value) {
            //Get value with two decimal digits
            return parseFloat(value).toFixed(2);
        }

        function resetFilters() {
            var id = vm.filter.log.client_id;
            vm.filter.log = {
                client_id: id,
                ts_from: null,
                ts_to: null
            };
        }

        function getDate(date) {
            var result = new Date(0);
            result.setSeconds(0);
            result.setMinutes(0);
            result.setHours(0);
            result.setDate(date.split('.')[0]);
            result.setMonth(date.split('.')[1] - 1);
            result.setYear(date.split('.')[2]);
            return result.getTime();
        }

        function now() {
            return new Date().getTime();
        }

        function getNumber(num) {
            var a = new Array();
            for (var i = 0; i < num; i++)
                a.push(i);
            return a;
        }

        //Init controller
        vm.init();
    }
})();
