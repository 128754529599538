(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('ActivateController', ActivateController);

    ActivateController.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils'];

    function ActivateController($state, $stateParams, $location, RegisterService, Info, Utils) {
        var vm = this;

        vm.data = {
            login: null,
            document_nr: null,
            total_netto: null,
            email: null,
            password: null,
            telephone_number: null,
            agreement1: false,
            agreement2: false,
            agreement3: false,
            agreement4: false,
            agreement5: false,
            agreement6: false
        };

        vm.activation = {
            startPassword: null,
            password: null,
            newRepeat: null,
            token: null
        };


        vm.activate = activate;
        vm.quickActivate = quickActivate;
        vm.userActivate = userActivate;

        angular.copy($stateParams, vm.activation);

        function activate() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            var activationToSend={};
            angular.copy(vm.activation, activationToSend);
            delete activationToSend["newRepeat"]
            RegisterService.activate(activationToSend)
                .then(function() {
                    Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą kodu klienta oraz nowego hasła.', 'login');
                    $state.go('login');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }

        function quickActivate() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            var activationToSend={};
            angular.copy(vm.activation, activationToSend);
            delete activationToSend["newRepeat"]
            RegisterService.quickActivate(activationToSend)
                .then(function() {
                    Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą kodu klienta oraz nowego hasła.', 'login');
                    $state.go('login');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }

        function userActivate() {
            $('.content').addClass('hidden');
            $('.loader.activator').removeClass('hidden');
            var activationToSend={};
            angular.copy(vm.activation, activationToSend);
            delete activationToSend["newRepeat"]
            RegisterService.userActivate(activationToSend)
                .then(function() {
                    Info.addStatement('Hasło do konta w Portalu VB Leasing zostało nadane. Proszę zalogować się za pomocą loginu oraz nowego hasła.', 'login');
                    $state.go('login');
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                }).catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                    $('.content').removeClass('hidden');
                    $('.loader.activator').addClass('hidden');
                });
        }
    }
})();
