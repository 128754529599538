(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        //Parent state
        $stateProvider.state('register', {
            parent: 'site',
            abstract: true,
            url: '/register?token',
            params: {
                    token: null
                },
            data: {
                    authorities: [],
                    pageTitle: 'Rejestracja do Portalu - VB Leasing'
                },
            views: {
                    'index@': {
                        templateUrl: 'app/views/register/register.html'
                    }
                }
        }) //Step 1
            .state('register1', {
                parent: 'register',
                url: '/1',
                data: {
                    pageTitle: 'Rejestracja do Portalu - VB Leasing'
                },
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/register-1.html',
                        controller: 'Register1Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    registration: function($q, $timeout, $state, $stateParams, Utils, RegisterService) {

                        var deferred = $q.defer();

                        // $timeout is an example; it also can be an xhr request or any other async function
                        $timeout(function() {
                            if (!Utils.isEmpty($stateParams.token)) {
                                RegisterService.getRegistration($stateParams)
                                    .then(function(response) {
                                        if (response.data.result != null) {
                                            $stateParams = response.data.result;
                                            deferred.resolve(response.data.result);
                                        } else {
                                            $state.go('register1');
                                            deferred.reject();
                                        }
                                    })
                                    .catch(function(response) {
                                        $state.go('register-fail-' + response.data.errorCode);
                                        deferred.reject();
                                    });
                            } else {
                                deferred.resolve(null);
                            }
                        });

                        return deferred.promise;
                    }
                }
            }) //Step 2
            .state('register2', {
                parent: 'register',
                url: '/2',
                params: {
                    token: null
                },
                data: {
                    authorities: [],
                    pageTitle: 'Rejestracja do Portalu - VB Leasing'
                },
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/register-2.html',
                        controller: 'Register2Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    registration: function($q, $timeout, $state, $stateParams, Utils, RegisterService) {

                        var deferred = $q.defer();

                        // $timeout is an example; it also can be an xhr request or any other async function
                        $timeout(function() {
                            if (Utils.isEmpty($stateParams.token)) {
                                $state.go('register1');
                                deferred.reject();
                            } else {
                                RegisterService.getRegistration($stateParams)
                                    .then(function(response) {
                                        if (response.data.result != null) {
                                            $stateParams = response.data.result;
                                            deferred.resolve(response.data.result);
                                        } else {
                                            $state.go('register1');
                                            deferred.reject();
                                        }
                                    })
                                    .catch(function(response) {
                                        $state.go('register-fail-' + response.data.errorCode);
                                        deferred.reject();
                                    });
                            }
                        });

                        return deferred.promise;
                    }
                }
            }) //Step 3
            .state('register3', {
                parent: 'register',
                url: '/3',
                data: {
                    authorities: [],
                    pageTitle: 'Rejestracja do Portalu - VB Leasing'
                },
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/register-3.html',
                        controller: 'Register3Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    registration: function($q, $timeout, $state, $stateParams, Utils, RegisterService) {

                        var deferred = $q.defer();

                        // $timeout is an example; it also can be an xhr request or any other async function
                        $timeout(function() {
                            if (Utils.isEmpty($stateParams.token)) {
                                $state.go('register1');
                                deferred.reject();
                            } else {
                                RegisterService.getRegistration($stateParams)
                                    .then(function(response) {
                                        if (response.data.result != null) {
                                            $stateParams = response.data.result;
                                            deferred.resolve(response.data.result);
                                        } else {
                                            $state.go('register1');
                                            deferred.reject();
                                        }
                                    })
                                    .catch(function(response) {
                                        $state.go('register-fail-' + response.data.errorCode);
                                        deferred.reject();
                                    });
                            }
                        });

                        return deferred.promise;
                    }
                }
            }).state('regulations', {
                parent: 'register',
                url: '/regulations',
                data: {
                    pageTitle: 'Regulamin - VB Leasing'
                },
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/regulations.html',
                        controller: 'Register3Controller',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    registration: function($q, $timeout, $state, $stateParams, Utils, RegisterService) {

                        var deferred = $q.defer();

                        // $timeout is an example; it also can be an xhr request or any other async function
                        $timeout(function() {
                            if (Utils.isEmpty($stateParams.token)) {
                                $state.go('register1');
                                deferred.reject();
                            } else {
                                RegisterService.getRegistration($stateParams)
                                    .then(function(response) {
                                        if (response.data.result != null) {
                                            $stateParams = response.data.result;
                                            deferred.resolve(response.data.result);
                                        } else {
                                            $state.go('register1');
                                            deferred.reject();
                                        }
                                    })
                                    .catch(function(response) {
                                        $state.go('register-fail-' + response.data.errorCode);
                                        deferred.reject();
                                    });
                            }
                        });

                        return deferred.promise;
                    }
                }
            }) //Confirmation of registration
            .state('register-finish', {
                parent: 'register',
                url: '/finish',
                data: {
                    authorities: [],
                    pageTitle: 'Rejestracja do Portalu - VB Leasing'
                },
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/finish.html'
                    }
                }
            }); //Failure during registration

        var errors = [204, 207, 208, 209, 2013, 2015, 2016, 2020];
        //Add all error states
        for (var errorIndex in errors) {
            $stateProvider.state('register-fail-' + errors[errorIndex], {
                parent: 'register',
                url: '/fail/' + errors[errorIndex],
                views: {
                    'step@register': {
                        templateUrl: 'app/views/register/' + errors[errorIndex] + '.html'
                    }
                }
            });
        }
    }
})();
