(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', '$scope', 'Auth', 'Principal'];

    function SidebarController($state, $scope, Auth, Principal) {
        var vm = this;

        //Declare functions
        vm.logout = logout;

        function logout() {
            //Logout through HTTP request
            Auth.logout().then(function() {
                //Go to login view
                $state.go('login');
            });
        }
    }
})();
