(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('error', {
                parent: 'site',
                url: '/error',
                views: {
                    'index@': {
                        templateUrl: 'app/views/error/error.html'
                    }
                }
            })
            .state('401', {
                parent: 'error',
                url: '/401',
                data: {
                    authorities: [],
                    pageTitle: 'Nie zalogowano - VB Leasing'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/401.html'
                    }
                }
            })
            .state('403', {
                parent: 'error',
                url: '/403',
                data: {
                    authorities: [],
                    pageTitle: 'Brak dostępu - VB Leasing'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/403.html'
                    }
                }
            })
            .state('404', {
                parent: 'error',
                url: '/404',
                data: {
                    authorities: [],
                    pageTitle: 'Podana strona nie istnieje - VB Leasing'
                },
                views: {
                    'error@error': {
                        templateUrl: 'app/views/error/404.html'
                    }
                }
            });
    }
})();
