(function() {
    'use strict';
	
    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('principalPermissions', ['Principal', function(Principal) {

			              return function(scope, element, attrs) {
				    var permissions  = attrs.principalPermissions.split(",");
				    if(!Principal.hasPermissions(permissions))
					    element.hide();
			};
		        }]);
})();
