(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('PasswordResetService', PasswordResetService);

    PasswordResetService.$inject = ['API_URL', '$http'];

    function PasswordResetService(API_URL, $http) {

        return {
            resetPassword: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password/reset',
                    data: data
                });
            },
            confirmResetPassword: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/password/reset/change',
                    data: data
                });
            },
            getChange: function(data) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/change',
                    params: data
                });
            }
        };
    }
})();
