(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$state', '$stateParams', 'UsersService', 'AccountService', 'Info', 'Principal'];

    function UsersController($state, $stateParams, UsersService, AccountService, Info, Principal) {
        var vm = this;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.userInterval = {
            current: null,
            currentMeasure: "dni",
            new: null,
            newMeasure: "dni",
        };
        vm.clientInterval = {
            current: null,
            currentMeasure: "dni",
            new: null,
            newMeasure: "dni",
        };

        //Declare functions
        vm.init = init;
        vm.resetFilters = resetFilters;
        vm.getUsers = getUsers;
        vm.getUserInterval = getUserInterval;
        vm.setUserInterval = setUserInterval;
        vm.getClientInterval = getClientInterval;
        vm.setClientInterval = setClientInterval;

        //Declare utils
        vm.validateFilter = validateFilter;

        //Init functions
        function init() {
            //Init filter
            vm.filter = {
                current: {
                    login: null,
                    email: null,
                    enable: null
                },
                query: {
                    login: null,
                    email: null,
                    enable: null
                }
            };

            //Init data
            vm.data = {
                error: null,
                result: {
                    users: []
                }
            };

            //Init interval
            vm.getUserInterval();

            vm.getClientInterval();

            //Copy URL parameters to current filter
            angular.copy($stateParams, vm.filter.query);

            //Get users from server
            vm.getUsers();
        }

        function resetFilters(event) {
            vm.filter.query = {
                login: null,
                email: null,
                enable: null
            };
        }

        function getUsers(event) {
            Info.clear();
            Info.setAction("Wyszukiwanie użytkowników...");
            //If event is not undefined
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Wyszukiwanie...";
                event.target.disabled = true;
            }
            //Validate the filter
            vm.validateFilter(vm.filter.query);
            //Get users JSON by new query
            UsersService.getUsers(vm.filter.query)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data = response.data;
                    //Update current filter without binding
                    angular.copy(vm.filter.query, vm.filter.current);
                    //Change URL to match the new query
                    $state.transitionTo('users', vm.filter.current, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Wyszukaj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function validateFilter(filter) {
            for (var key in filter) {
                if (filter[key] == "") {
                    filter[key] = null;
                }
            }
        }

        function setUserInterval(event) {
            Info.clear();
            Info.setAction("Aktualizowanie interwału hasła...");
            //If event is not undefined
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Aktualizowanie...";
                event.target.disabled = true;
            }
            var userInterval = vm.userInterval.new;
            switch (vm.userInterval.newMeasure) {
            case "dni":
                userInterval *= 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                break;
            case "miesiące":
                userInterval *= 30 /*dni*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                break;
            case "lata":
                userInterval *= 365 /*dni*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                break;
            }

            AccountService.changeUserPasswordInterval(userInterval)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.userInterval.current = response.data.result;
                    Info.addStatement('Pomyślnie zaktualizowano interwał zmiany hasła.');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function() {
                    //If event is not undefined
                    if (event != undefined) {
                        //Notify the user of searching and disable the button
                        event.target.innerText = "Aktualizuj interwał";
                        event.target.disabled = false;
                    }
                    vm.getUserInterval();
                    Info.clearAction();
                });
        }

        function getUserInterval() {
            Info.clear();
            Info.setAction("Pobieranie interwału hasła...");
            Principal.hasAuthority('PASSWORD_INTERVAL_CHANGE_RESOURCE').then(function(response) {
                if (response) {
                    AccountService.getUserPasswordInterval()
                        //If successful
                        .then(function(response) {
                            //Compute day count
                            var userInterval = response.data.result;
                            //Convert to days
                            userInterval = userInterval / 1000 /*ms*/ / 60 /*s*/ / 60 /*min*/ / 24 /*h*/ ;
                            vm.userInterval.current = userInterval;
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        })
                        .finally(function() {
                            Info.clearAction();
                        });
                }
            });
        }

        function setClientInterval(event) {
                    Info.clear();
                    Info.setAction("Aktualizowanie interwału hasła...");
                    //If event is not undefined
                    if (event != undefined) {
                        //Notify the user of searching and disable the button
                        event.target.innerText = "Aktualizowanie...";
                        event.target.disabled = true;
                    }
                    var clientInterval = vm.clientInterval.new;
                    switch (vm.clientInterval.newMeasure) {
                    case "dni":
                        clientInterval *= 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "miesiące":
                        clientInterval *= 30 /*dni*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    case "lata":
                        clientInterval *= 365 /*dni*/ * 24 /*h*/ * 60 /*min*/ * 60 /*s*/ * 1000 /*ms*/ ;
                        break;
                    }

                    AccountService.changeClientPasswordInterval(clientInterval)
                        //If successful
                        .then(function(response) {
                            //Set data to controller data
                            vm.clientInterval.current = response.data.result;
                            Info.addStatement('Pomyślnie zaktualizowano interwał zmiany hasła.');
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        })
                        .finally(function() {
                            //If event is not undefined
                            if (event != undefined) {
                                //Notify the user of searching and disable the button
                                event.target.innerText = "Aktualizuj interwał";
                                event.target.disabled = false;
                            }
                            vm.getClientInterval();
                            Info.clearAction();
                        });
                }

        function getClientInterval() {
            Info.clear();
            Info.setAction("Pobieranie interwału hasła...");
            Principal.hasAuthority('PASSWORD_INTERVAL_CHANGE_RESOURCE').then(function(response) {
                        if (response) {
                            AccountService.getClientPasswordInterval()
                                //If successful
                                .then(function(response) {
                                    //Compute day count
                                    var clientInterval = response.data.result;
                                    //Convert to days
                                    clientInterval = clientInterval / 1000 /*ms*/ / 60 /*s*/ / 60 /*min*/ / 24 /*h*/ ;
                                    vm.clientInterval.current = clientInterval;
                                })
                                //If there were errors
                                .catch(function(response) {
                                    Info.addErrorCode(response.data.errorCode);
                                })
                                .finally(function() {
                                    Info.clearAction();
                                });
                        }
            });
        }

        //Init controller
        vm.init();
    }
})();
