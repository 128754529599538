(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$state', '$stateParams', 'AccountService', 'Principal', 'Info', 'Utils'];

    function SettingsController($state, $stateParams, AccountService, Principal, Info, Utils) {
        var vm = this;

        //Declare variables
        vm.data = null;
        vm.eInvoice = Principal.getProperty('e_invoice');
        vm.pipeRegex = Utils.getPipeRegex();

        //Declare functions
        vm.init = init;
        vm.changePassword = changePassword;
        vm.changeEmail = changeEmail;
        vm.activateEInvoice = activateEInvoice;
        vm.acceptAgreements = acceptAgreements;

        //Init functions
        function init() {
            //Init data
            vm.data = {
                password: {
                    currentValue: '',
                    newValue: '',
                    newRepeat: ''
                },
                email: {
                    currentValue: '',
                    newValue: '',
                    newRepeat: ''
                },
                agreements: {
                    agreement1: false,
                    agreement2: false,
                    agreement3: false,
                    agreement4: false
                },
                eInvoice: false

            };

            AccountService.getRegistrationForUser($stateParams)
                .then(function(response) {
                    if (response.data.result != null) {
                        vm.data.agreements.agreement1 = response.data.result.agreement4;
                        vm.data.agreements.agreement2 = response.data.result.agreement5;
                        vm.data.agreements.agreement3 = response.data.result.agreement6;
                        vm.data.agreements.agreement4 = response.data.result.agreement7;
                    }
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function acceptAgreements(event) {
            Info.clear();
            Info.setAction("Aktualizowanie zgód...");
            //Notify the user of sending and disable the button
            event.target.innerText = "Wysyłanie...";
            event.target.disabled = true;
            AccountService.acceptAgreements(vm.data.agreements)
                //If successful
                .then(function(response) {
                    Info.addStatement("Zgody zostały zmienione.");
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Aktualizuj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function changePassword(event) {
            Info.clear();
            Info.setAction("Aktualizowanie hasła...");
            //If event is not undefined
            if (event != undefined) {
                //Notify the user of changing the password and disable the button
                event.target.innerText = "Aktualizowanie...";
                event.target.disabled = true;
            }
            //Send change request
            AccountService.changePassword(vm.data.password)
                //If successful
                .then(function(response) {
                    Info.addStatement("Hasło zostało zmienione.");
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Aktualizuj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });

        }

        function changeEmail(event) {
            Info.clear();
            Info.setAction("Aktualizowanie adresu e-mail...");
            //If event is not undefined
            if (event != undefined) {
                //Notify the user of changing the email and disable the button
                event.target.innerText = "Aktualizowanie...";
                event.target.disabled = true;
            }
            //Send change request
            AccountService.changeEmail(vm.data.email)
                //If successful
                .then(function(response) {
                    Info.addStatement("Wiadomość z linkiem aktywującym zmianę adresu e-mail do obsługi Portalu VB Leasing została wysłana na podany adres e-mail.");
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Aktualizuj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function activateEInvoice(event) {
            Info.clear();
            Info.setAction("Aktywowanie usługi e-faktura...");
            //If event is not undefined
            if (event != undefined) {
                //Notify the user of activating and disable the button
                event.target.innerText = "Aktywowanie...";
                event.target.disabled = true;
            }
            //If the user accepted
            if (vm.data.eInvoice == true) {
                //Send activation request
                AccountService.activateEInvoice()
                    //If successful
                    .then(function(response) {
                        Info.addStatement("Pomyślnie aktywowano usługę e-faktura.");
                        vm.eInvoice = true;
                    })
                    //If there were errors
                    .catch(function(response) {
                        Info.addErrorCode(response.data.errorCode);
                    })
                    .finally(function(response) {
                        //If event is not undefined
                        if (event != undefined) {
                            //Enable the button and return to former text
                            event.target.innerText = "Aktywuj";
                        }
                        Info.clearAction();
                    });
            }
        }

        //Init controller
        vm.init();
    }
})();
