(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('PassworExpiredService', PassworExpiredService);

    PassworExpiredService.$inject = ['API_URL', '$http'];

    function PassworExpiredService(API_URL, $http) {

        return {
            changeExpiredPassword: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password/expired',
                    data: data
                });
            }
        };
    }
})();
