(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('PasswordService', PasswordService);

    PasswordService.$inject = ['API_URL','$http'];

    function PasswordService (API_URL, $http) {
 
		        return {
		};
	    }
})();
