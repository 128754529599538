(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('SettingsService', SettingsService);

    SettingsService.$inject = ['API_URL', '$http'];

    function SettingsService(API_URL, $http) {

        return {
            'changePassword': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password',
                    data: data
                });
            },
            'changeEmail': function(emailSet) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/settings'
                });
            },
            'activateEInvoice': function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/settings'
                });
            }
        };
    }
})();
