(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('ClientsService', ClientsService);

    ClientsService.$inject = ['API_URL', '$http'];

    function ClientsService(API_URL, $http) {

        return {
            getClient: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/clients/',
                    params: filter
                });
            },
            getClients: function(filter, page) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/clients/' + page,
                    params: filter
                });
            },
            getClientLogs: function(filter) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/clients/logs',
                    params: filter
                });
            },
            unlockClient: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/clients/unlock',
                    data: data
                });
            }
        };
    }
})();
