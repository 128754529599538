(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('AgreementsService', AgreementsService);

    AgreementsService.$inject = ['API_URL', '$http'];

    function AgreementsService(API_URL, $http) {

        return {
            getAgreements: function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/agreements',
                    params: params
                });
            }
        };
    }

})();
