(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('PermissionsService', PermissionsService);

    PermissionsService.$inject = ['API_URL', '$http'];

    function PermissionsService(API_URL, $http) {

        return {
            getPermissions: function(role_id) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/permissions',
                });
            }
        };
    }
})();
