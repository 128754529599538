(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .service('HttpPendingRequestsService', HttpPendingRequestsService)
        .factory('globalPromiseInterceptor', globalPromiseInterceptor);

    globalPromiseInterceptor.$inject = ['$q', 'HttpPendingRequestsService', '$rootScope', 'API_URL'];

    function globalPromiseInterceptor($q, HttpPendingRequestsService, $rootScope, API_URL) {

        function isRequestToAPI(request) {
            if (request.url.indexOf(API_URL) == 0)
                return true;
            else
                return false;
        }

        function updateSession(request) {
            $rootScope.Principal.updateLastAccessedTime();
        }

        return {
            request: function(config) {
                config = config || {};
                if (isRequestToAPI(config)) {
                    if (config.timeout === undefined && !config.noCancelOnRouteChange) {
                        config.timeout = HttpPendingRequestsService.newTimeout();
                    }
                    updateSession(config);
                }
                return config;
            },
            responseError: function(response) {
                if (response.config.timeout.isGloballyCancelled) {
                    return $q.defer().promise;
                }
                return $q.reject(response);
            }
        };
    }

    HttpPendingRequestsService.$inject = ['$q'];

    function HttpPendingRequestsService($q) {
        var cancelPromises = [];

        function newTimeout() {
            var cancelPromise = $q.defer();
            cancelPromises.push(cancelPromise);
            return cancelPromise.promise;
        }

        function cancelAll() {
            angular.forEach(cancelPromises, function(cancelPromise) {
                cancelPromise.promise.isGloballyCancelled = true;
                cancelPromise.resolve();
            });
            cancelPromises.length = 0;
        }

        return {
            newTimeout: newTimeout,
            cancelAll: cancelAll
        };
    }

})();
