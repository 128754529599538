(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('AccountService', AccountService);

    AccountService.$inject = ['API_URL', '$http'];

    function AccountService(API_URL, $http) {

        return {
            getAccount: function() {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account'
                });
            },
            /*REGISTRATION*/
            'getRegistration': function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/register',
                    params: params
                });
            },
            'getRegistrationForUser': function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/register/user',
                    params: params
                });
            },
            'createRegistration': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register',
                    data: data
                });
            },
            activateRegistration: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/register',
                    data: data
                });
            },
            /*PASSWORD*/
            changePassword: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password',
                    data: data
                });
            },
            changeExpiredPassword: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password/expired',
                    data: data
                });
            },
            resetPassword: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/password/reset',
                    data: data
                });
            },
            /*EMAIL*/
            changeEmail: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/email',
                    data: data
                });
            },
            /*EMAIL BY ADMIN*/
            changeEmailByAdmin: function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/emailByAdmin',
                    data: data
                });
            },
            /*EINVOICE*/
            activateEInvoice: function() {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/einvoice'
                });
            },
            /*PASSWORD INTERVAL*/
            getUserPasswordInterval: function() {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/userPasswordInterval'
                });
            },
            changeUserPasswordInterval: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/userPasswordInterval',
                    data: data
                });
            },
            /*CLIENT PASSWORD INTERVAL*/
            getClientPasswordInterval: function() {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/clientPasswordInterval'
                });
            },
            changeClientPasswordInterval: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/clientPasswordInterval',
                    data: data
                });
            },
            /*CHANGE*/
            getChange: function(params) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/change',
                    params: params
                });
            },
            applyChange: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/change',
                    data: data
                });
            },
            acceptAgreements: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/agreements/accept',
                    data: data
                });
            }
        };
    }

})();
