(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('AgreementsController', AgreementsController);

    AgreementsController.$inject = ['$state', '$stateParams', 'AgreementsService', 'DateUtils', 'Info', 'Principal'];

    function AgreementsController($state, $stateParams, AgreementsService, DateUtils, Info, Principal) {
        var vm = this;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.sort = ['-agreement_date', '+agreement_nr'];

        //Declare functions
        vm.init = init;
        vm.getAgreements = getAgreements;
        vm.resetFilters = resetFilters;
        vm.validateFilter = validateFilter;
        vm.sortBy = sortBy;
        vm.isFilterFilled = isFilterFilled;
        vm.Date = DateUtils.convertDateFromServer;

        //Init functions
        function init() {
            //Init filter
            vm.filter = {
                current: {
                    login: null,
                    agreement_nr: null,
                    agreement_date_from: null,
                    agreement_date_to: null,
                    end_plan_date_from: null,
                    end_plan_date_to: null,
                    subject: null,
                    registration_number: null,
                    status: null
                },
                query: {
                    login: null,
                    agreement_nr: null,
                    agreement_date_from: null,
                    agreement_date_to: null,
                    end_plan_date_from: null,
                    end_plan_date_to: null,
                    subject: null,
                    registration_number: null,
                    status: null
                }
            };

            vm.data = {
                error: null,
                result: {
                    agreements: []
                }
            };

            //Copy URL parameters to current filter
            angular.copy($stateParams, vm.filter.query);

            //Check if current user is a client
            Principal.isClient()
                .then(function(isClient) {
                    //If is client
                    if (isClient) {
                        vm.filter.query.login = Principal.getLogin();
                    }
                    //If the filter is filled
                    if (isFilterFilled(vm.filter.query)) {
                        //Get documents from server
                        vm.getAgreements();
                    } else {
                        Info.addInfo("Proszę uzupełnić przynajmniej jedno z pól: KOD KLIENTA lub NR UMOWY.");
                    }
                });
        }

        vm.init();

        function resetFilters(event) {
            if (event != undefined) {
                if (event.target.localName != "button") {
                    event.target = event.target.parentNode;
                }
                //Notify the user of refreshing by disabling the button
                event.target.disabled = true;
            }
            if (event != undefined) {
                //Notify the user of end of refreshing by enabling the button
                event.target.disabled = false;
                vm.filter.query.agreement_nr = null;
                vm.filter.query.login = null;
                vm.filter.query.agreement_date_from = null;
                vm.filter.query.agreement_date_to = null;
                vm.filter.query.end_plan_date_from = null;
                vm.filter.query.end_plan_date_to = null;
                vm.filter.query.subject = null;
                vm.filter.query.registration_number = null;
                vm.filter.query.status = null;
            }
        }

        function getAgreements(event) {
            vm.data = {
                error: null,
                result: {
                    agreements: []
                }
            };
            Info.clear();
            Info.setAction("Pobieranie umów...");
            //If event is not undefined     
            if (event != undefined) {
                //Notify the user of searching and disable the button
                event.target.innerText = "Wyszukiwanie...";
                event.target.disabled = true;
            }
            vm.validateFilter(vm.filter.query);
            //Get documents JSON by new query
            AgreementsService.getAgreements(vm.filter.query)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data = response.data;
                    //Update current filter without binding
                    angular.copy(vm.filter.query, vm.filter.current);
                    //Change URL to match the new query
                    $state.transitionTo('agreements', vm.filter.current, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                    if (response.data.result.agreements.length == 0)
                        Info.addStatement('Brak umów spełniających podane kryteria.');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Wyszukaj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function sortBy(property) {
            //If sort contains this property descending
            if (vm.sort.indexOf('-' + property) == 0) {
                //Push it as ascending instead
                vm.sort = ['+' + property];
            } //If sort contains this property ascending
            else if (vm.sort.indexOf('+' + property) == 0) {
                //Delete it from sort
                vm.sort = ['-agreement_date', '+agreement_nr'];
            } //If sort does not contain this property at all
            else {
                //Push it as descending
                vm.sort = ['-' + property];
            }
        }

        function isFilterFilled(filter) {
            //Check all keys
            for (var key in filter) {
                //If the value matched by key is not empty return true
                if (filter[key] != null && filter[key] != undefined && filter[key] != "") {
                    return true;
                }
            }
            //If there are no values in the filter return false
            return false;
        }

        function validateFilter(filter) {
            //Check all keys
            for (var key in filter) {
                //If the value matched by key is an empty string
                if (filter[key] == "" || filter[key] !== filter[key] || filter[key] == undefined) {
                    //Substitue it with null
                    filter[key] = null;
                }
            }
        }
    }
})();
