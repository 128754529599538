(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('Utils', Utils);

    Utils.$inject = [];

    function Utils() {

        var utils = {
            isEmpty: isEmpty,
            isNotAmount: isNotAmount,
            isNotDate: isNotDate,
            getDate: getDate,
            getPipeRegex: getPipeRegex
        };

        return utils;

        function isEmpty(value) {
            if (value == null || value.toString() == '' || value !== value)
                return true;
            else
                return false;
        }

        function isNotAmount(value) {
            if (value == null || value.toString() == '' || isNaN(value.toString().replace(',', '.')))
                return true;
            else
                return false;
        }

        function isNotDate(value) {
            if (value == null || value.toString() == '' || !/^\d{2}\.\d{2}\.\d{4}$/.test(value))
                return true;
            else
                return false;
        }

        function getDate(date) {
            if (date) {
                date = new Date(date);
                return ((date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "." + (date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) + "." + date.getFullYear());
            }
        }

        function getPipeRegex() {
            return /\|/g;
        }
    }
})();
