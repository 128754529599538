(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('newRoleController', newRoleController);

    newRoleController.$inject = ['$state', 'RolesService', 'PermissionsService', 'Info'];

    function newRoleController($state, RolesService, PermissionsService, Info) {
        var vm = this;

        //Declare variables
        vm.data = null;
        vm.warnings = [];

        //Declare functions
        vm.init = init;
        vm.createRole = createRole;
        vm.getPermissions = getPermissions;
        vm.updateRolePermissions = updateRolePermissions;
        vm.generateUpdateObject = generateUpdateObject;

        //Init functions
        function init() {
            //Init data 
            vm.data = {
                label: null,
                name: null,
                permissions: null
            };

            vm.getPermissions();
        }

        function createRole() {
            RolesService.createRole(vm.data)
                .then(function(response) {
                    vm.updateRolePermissions(response.data.result);
                })
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getPermissions() {
            //Get role JSON
            PermissionsService.getPermissions()
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.permissions = response.data.result.permissions;
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function updateRolePermissions(role_id) {
            // Update role permissions
            RolesService.updateRolePermissions(vm.generateUpdateObject(role_id))
                //If successful
                .then(function(response) {
                    Info.addStatement('Pomyślnie dodano rolę ' + vm.data.label + '.', 'roles');
                    $state.go('roles');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function generateUpdateObject(role_id) {
            var perm_ids = [];
            for (var permission in vm.data.permissions) {
                if (vm.data.permissions[permission].checked)
                    perm_ids.push(vm.data.permissions[permission].perm_id);
            }

            return {
                role_id: role_id,
                perm_ids: perm_ids
            };
        }


        //Init controller
        vm.init();
    }
})();
