(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('document', function() {
            return {
                restrict: 'E,A',
                templateUrl: 'app/components/util/document.html',
                link: function(scope, element, attrs) {
                    var document = scope.document;

                    document.netto = [];
                    document.vat = [];
                    document.brutto = [];
                    if (document.netto != null) {
                        put(document.total_netto, document.currency, document.netto);
                    }
                    if (document.currency_vat == null) {
                        put(document.total_brutto, document.currency, document.brutto);
                        if (document.total_vat != 0)
                            put(document.total_vat, document.currency, document.vat);
                    } else {
                        put(document.total_netto, document.currency, document.brutto);
                        if (document.total_vat != 0) {
                            put(document.total_vat, document.currency_vat, document.vat);
                            put(document.total_vat, document.currency_vat, document.brutto);
                        }
                    }

                    function put(value, currency, dest) {
                        if (value != null) {
                            dest.push(scope.vm.getValue(value) + " " + currency);
                        }
                    }
                }
            };
        });
})();
