(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('phone', ['Principal', function(Principal) {

            return {
                restrict: 'E',
                replace: 'true',
                template: "<input type='email' class='form-control' data-html='true' data-trigger='manual'>",
                link: function(scope, element, attrs) {

                    function isValid(elem) {
                        if (elem.hasClass('ng-valid') && !elem.hasClass('ng-invalid'))
                            return true;
                        else
                            return false;
                    }

                    function makeInvalid(elem) {
                        addClass(elem, 'ng-invalid');
                        removeClass(elem, 'ng-valid');
                    }

                    function makeValid(elem) {
                        removeClass(elem, 'ng-invalid');
                        addClass(elem, 'ng-valid');
                    }

                    function addClass(elem, cls) {
                        if (!elem.hasClass(cls))
                            elem.addClass(cls);
                    }

                    function removeClass(elem, cls) {
                        if (elem.hasClass(cls))
                            elem.removeClass(cls);
                    }

                    //On each key up
                    $(element).keyup(jQuery.proxy(function(scope) {
                        console.log('KEYUP');
                        var pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[\.]{1}[a-zA-Z]{2,}$/;
                        //VALIDATE
                        //If email field is probably valid, but should not be identical to current email
                        if (this.attr('data-new-email') != undefined && Principal.getProperty('email') == this.val()) {
                            makeInvalid(this);
                            addClass(this, 'ng-invalid-new');
                        } else {
                            removeClass(this, 'ng-invalid-new');
                        }
                        //If repeat email field is probably valid, but should be identical to new email
                        if (this.attr('data-repeat-email') != undefined && $("input[data-new-email]").val() != this.val()) {
                            makeInvalid(this);
                            addClass(this, 'ng-invalid-repeat');
                        } else {
                            removeClass(this, 'ng-invalid-repeat');
                        }
                        //If repeat email field is probably valid, but should be identical to new email
                        if (!pattern.test(this.val())) {
                            makeInvalid(this);
                            addClass(this, 'ng-invalid-pattern');
                        } else {
                            removeClass(this, 'ng-invalid-pattern');
                        }
                        //If repeat email field is probably valid, but should be identical to new email
                        if (this.val().length > 256) {
                            makeInvalid(this);
                            addClass(this, 'ng-invalid-maxlength');
                        } else {
                            removeClass(this, 'ng-invalid-maxlength');
                        }

                    }, element));
                    //On change
                    $(element).change(jQuery.proxy(function() {
                        console.log('CHANGE');
                        $(element).trigger('keyup');
                        //COSTRUCT TOOLTIP BASED ON VALIDATION
                        var tooltip = "";
                        //If the email field is invalid
                        if (!isValid(this)) {
                            var invalid = false;
                            //If it does not match email pattern
                            if (this.hasClass('ng-invalid-pattern')) {
                                invalid = true;
                                tooltip += '<p style="font-size:10pt;font-weight:normal;margin:0px;">Wprowadź poprawny adres e-mail.</p>';
                            }
                            //If it is too long
                            if (this.hasClass('ng-invalid-maxlength')) {
                                invalid = true;
                                tooltip += '<p style="font-size:10pt;font-weight:normal;margin:0px;">Adres e-mail może zawierać maksymalnie 256 znaków.</p>';
                            }
                            //If repeat is invalid
                            if (this.hasClass('ng-invalid-new')) {
                                invalid = true;
                                tooltip += '<p style="font-size:10pt;font-weight:normal;margin:0px;">Nowy adres e-mail jest identyczny jak dotychczasowy.</p>';
                            }
                            //If repeat is invalid
                            if (this.hasClass('ng-invalid-repeat')) {
                                invalid = true;
                                tooltip += '<p style="font-size:10pt;font-weight:normal;margin:0px;">Podane adresy e-mail nie są identyczne.</p>';
                            }

                            //If is not invalid
                            if (!invalid) {
                                this.attr('data-original-title', '');
                                this.tooltip('fixTitle').tooltip('hide');
                                makeValid(this);
                            } //If is invalid and current tooltip is different than generated tooltip - avoid flickering
                            else if (this.attr('data-original-title') != tooltip) {
                                //Swap and show it
                                this.attr('data-original-title', tooltip);
                                this.tooltip('fixTitle').tooltip('show');
                                makeInvalid(this);
                            }
                        } //If is valid
                        else {
                            this.attr('data-original-title', '');
                            this.tooltip('fixTitle').tooltip('hide');
                            makeValid(this);
                        }
                    }, element));
                    $(element).blur(jQuery.proxy(function() {
                        console.log('BLUR');
                        this.trigger('change');
                    }, element));
                }
            };
        }]);
})();
