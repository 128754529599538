(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['$scope', '$state', '$stateParams', '$filter', 'DocumentsService', 'DateUtils', 'Info',
    'Principal', 'API_URL', '$http', 'Utils', 'FileSaver', 'Blob'];

    function DocumentsController($scope, $state, $stateParams, $filter, DocumentsService, DateUtils, Info, Principal,
    API_URL, $http, Utils, FileSaver, Blob) {
        var vm = this;

        //Declare variables
        vm.filter = null;
        vm.data = null;
        vm.additionalInfo = null;
        vm.sort = ['-date_issue', '+document_nr'];

        //vm.sortingOrder = {field:'date_document_open', order:'DESC'};

        vm.sortingOrder = [
            {field:'date_issue', order:'DESC'},
            {field:'document_nr', order:'ASC'},
            {field:'agreement_nr', order:'DESC'},
            {field:'document_type', order:'DESC'},
            {field:'invoice_reference', order:'DESC'},
            {field:'date_payment', order:'DESC'},
            {field:'total_netto', order:'DESC'},
            {field:'total_vat', order:'DESC'},
            {field:'total_brutto', order:'DESC'},
            {field:'date_document_open', order:'DESC'}
        ];

        vm.types = [];
        vm.client = null;
        vm.clientEInvoice = null;
        vm.lastLoginDate = null;

        //Declare functions
        vm.init = init;
        vm.resetFilters = resetFilters;
        vm.getDocuments = getDocuments;
        vm.downloadMergedPDF = downloadMergedPDF;
        vm.downloadDocument = downloadDocument;
        vm.downloadXML = downloadXML;
        vm.downloadExcel = downloadExcel;
        vm.getDocumentTypes = getDocumentTypes;
        vm.sortBy = sortBy;
        vm.isSortedWithOrder = isSortedWithOrder;

        //Declare utils
        vm.getDate = DateUtils.convertDateFromServer;
        vm.getValue = getValue;
        vm.isFilterFilled = isFilterFilled;
        vm.isFieldFilled = isFieldFilled;
        vm.validateFilter = validateFilter;
        vm.isDocumentRead = isDocumentRead;

        //Declare pagination functions
        vm.first = first;
        vm.previous = previous;
        vm.next = next;
        vm.last = last;
        vm.getDocs = getDocs;
        vm.open = open;

        //Init functions
        function init() {
            //Init filter
            vm.filter = {
                current: {
                    login: null,
                    agreement_nr: null,
                    document_nr: null,
                    date_issue_from: null,
                    date_issue_to: null,
                    document_types: [],
                    total_netto_from: null,
                    total_netto_to: null,
                    currency: null,
                    date_document_open_from: null,
                    date_document_open_to: null,
                    date_payment_from: null,
                    date_payment_to: null,
                    sorting_order: vm.sortingOrder
                },
                query: {
                    login: null,
                    agreement_nr: null,
                    document_nr: null,
                    date_issue_from: null,
                    date_issue_to: null,
                    document_types: [],
                    total_netto_from: null,
                    total_netto_to: null,
                    currency: null,
                    date_document_open_from: null,
                    date_document_open_to: null,
                    date_payment_from: null,
                    date_payment_to: null,
                    sorting_order: vm.sortingOrder
                }
            };

            //Init data
            vm.data = {
                error: null,
                result: {
                    documents: []
                }
            };
            vm.page = {
                number: 1,
                totalPages: 1,
                newNumber: 1
            };

            vm.enabled = {
                first: false,
                prev: false,
                next: false,
                last: false
            };


            //Copy URL parameters to current filter
            angular.copy($stateParams, vm.filter.query);
            vm.filter.query.document_types = [];
            if (typeof $stateParams.document_types == "string") {
                vm.filter.query.document_types.push($stateParams.document_types);
            } else {
                for (var type in $stateParams.document_types) {
                    vm.filter.query.document_types.push($stateParams.document_types[type]);
                }
            }


            //Check if current user is a client
            Principal.isClient()
                .then(function(isClient) {
                    vm.client = isClient;
                    //If is client
                    if (isClient) {
                        vm.filter.query.login = Principal.getLogin();
                        vm.clientEInvoice = Principal.isEInvoice();
                        vm.lastLoginDate = Principal.getLastLoginDate();
                        vm.lastLoginDate = vm.lastLoginDate !== undefined ? vm.lastLoginDate : null;
                    }

                    if (vm.filter.query.date_issue_from === null || vm.filter.query.date_issue_from === undefined) {
                        var date = new Date(new Date().getTime() - (1000 /*ms*/ * 60 /*s*/ * 60 /*min*/ * 24 /*h*/ * 30 /*days*/ ));
                        vm.filter.query.date_issue_from = String(date.getTime());
                        $(".date-picker[ng-model='vm.filter.query.date_issue_from']").datepicker('update');
                    }

                    //If the filter is filled
                    if (isFilterFilled(vm.filter.query)) {
                        //Get documents from server
                        vm.getDocuments();
                    } else {
                        //If is client
                        if (isClient) {
                            Info.addInfo("Proszę uzupełnić przynajmniej jedno z pól: KOD KLIENTA, NR UMOWY lub NR DOKUMENTU.");
                        } else {
                            Info.addInfo("Proszę uzupełnić przynajmniej jedno z pól: KOD KLIENTA, NR UMOWY, NR DOKUMENTU lub DATA OTWARCIA.");
                        }
                    }
                });

            DocumentsService.getAdditionalInfo(Principal.getLogin()).then(function(response){
                vm.additionalInfo = response.data;
            });

            // Init doc type dropdown
            $('#docTypeDropdown').multiselect({
                nonSelectedText: 'Wszystkie',
                allSelectedText: 'Wszystkie',
                numberDisplayed: 1,
                nSelectedText: ' zaznaczono',
                buttonWidth: '100%',
                inheritClass: true,
                disabledText: 'Brak typów',
                maxHeight: 250,
                selectedClass: 'docTypeDropdownOptionSelected',
                optionClass: 'docTypeDropdownOption',
                onChange: function(option, checked, select) {
                    if (checked)
                        vm.filter.query.document_types.push(option[0].value);
                    else
                        vm.filter.query.document_types.splice(vm.filter.query.document_types.indexOf(option[0].value), 1);

                }
            });
            $('#docTypeDropdown').multiselect('dataprovider', vm.types);
            $('#docTypeDropdown').multiselect('rebuild');


        }

        function first() {
            if (vm.page.number > 1) {
                vm.page.number = 1;
                getDocs();
            }
        }

        function previous() {
            if (vm.page.number > 1) {
                vm.page.number = vm.page.number - 1;
                getDocs();
            }
        }

        function next() {
            if (vm.page.number < vm.page.totalPages) {
                vm.page.number = vm.page.number + 1;
                getDocs();
            }
        }

        function last() {
            if (vm.page.number < vm.page.totalPages) {
                vm.page.number = vm.page.totalPages;
                getDocs();
            }
        }

        function open() {
            //if (vm.page.newNumber === parseInt(vm.page.newNumber, 10) && vm.page.newNumber >= 1 && vm.page.newNumber <= vm.page.totalPages ) {
            if (isNumeric(vm.page.newNumber) && vm.page.newNumber >= 1 && vm.page.newNumber <= vm.page.totalPages) {
                vm.page.number = parseInt(vm.page.newNumber);
                getDocs();
            }
        }

        function isNumeric(num){
          return !isNaN(num)
        }

        function getDocs() {
            //Validate the filter
            vm.validateFilter(vm.filter.current);
            //Get documents JSON by new query
            vm.filter.current.sorting_order = vm.sortingOrder;
            DocumentsService.getDocumentsPaged(vm.filter.current, vm.page.number)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.errorCode = response.data.errorCode;
                    vm.data.result = response.data.result.documents;
                    vm.page.totalPages = response.data.result.pagesNumber;


                    angular.forEach(vm.data.result.documents, function(document){
                        document['additional_info'] = getDocumentRowToolTip(document);
                    });


                    if (response.data.result.documents.documents.length == 0)
                        Info.addStatement('Brak dokumentów spełniających podane kryteria.');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                //If event is not undefined
                    if (event != undefined) {
                    //Enable the button and return to former text
                        event.target.innerText = "Wyszukaj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function resetFilters() {
            vm.filter.query = {
                login: null,
                agreement_nr: null,
                document_nr: null,
                date_issue_from: null,
                date_issue_to: null,
                document_types: [],
                total_netto_from: null,
                total_netto_to: null,
                currency: null,
                date_document_open_from: null,
                date_document_open_to: null
            };
            $("input.date-picker").trigger("clearDate");
            $('#docTypeDropdown').multiselect('deselectAll', false);
            $('#docTypeDropdown').multiselect('rebuild');

            //Check if current user is a client
            Principal.isClient()
                .then(function(isClient) {
                    vm.client = isClient;

                    //If is client
                    if (isClient) {
                        vm.filter.query.login = Principal.getLogin();
                    }
                });
        }

        function getDocuments(event) {
            vm.data = {
                error: null,
                result: {
                    documents: []
                }
            };
            Info.clear();
            Info.setAction("Wyszukiwanie dokumentów...");
            //If event is not undefined
            if (event != undefined) {
                //Notify he user of searching and disable the button
                event.target.innerText = "Wyszukiwanie...";
                event.target.disabled = true;
            }
            //Validate the filter
            vm.validateFilter(vm.filter.query);
            //Get documents JSON by new query
            vm.filter.query.sorting_order = vm.sortingOrder;
            DocumentsService.getDocumentsPaged(vm.filter.query, vm.page.number)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.errorCode = response.data.errorCode;
                    vm.data.result = response.data.result.documents;
                    vm.page.totalPages = response.data.result.pagesNumber;
                    vm.page.number = 1;


                    angular.forEach(vm.data.result.documents, function(document){
                        document['additional_info'] = getDocumentRowToolTip(document);
                    });



                    vm.filter.current = {};
                    //Update current filter without binding
                    angular.copy(vm.filter.query, vm.filter.current);
                    //Change URL to match the new query
                    $state.transitionTo('documents', vm.filter.current, {
                        location: true,
                        inherit: true,
                        relative: $state.$current,
                        notify: false
                    });
                    if (response.data.result.documents.documents.length == 0)
                        Info.addStatement('Brak dokumentów spełniających podane kryteria.');

                    if(response.data.result.documentsNumber > 0)
                        Info.addStatement("Znaleziono " + response.data.result.documentsNumber + " dokumentów spełniających warunki zapytania.");
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function(response) {
                    //If event is not undefined
                    if (event != undefined) {
                        //Enable the button and return to former text
                        event.target.innerText = "Wyszukaj";
                        event.target.disabled = false;
                    }
                    Info.clearAction();
                });
        }

        function downloadDocument(index, document) {
            if (document.e_invoice || !vm.client || vm.clientEInvoice) {
                Info.setAction("Pobieranie dokumentu...");
                DocumentsService.getDocument(document.transaction_id, document.template, document.login)
                    .then(function(response) {
                        var file = new Blob([response.data], { type: "application/pdf" });
                        FileSaver.saveAs(file,
                            "Dokument nr " + document.document_nr + " z dnia " + new Date().toLocaleDateString() +
                            ".pdf");
                        Info.addStatement("Pomyślnie pobrano dokument.");

                        //Update date opened
                        if (vm.client && !document.date_document_open) {
                            document.date_document_open = new Date().getTime();
                            Principal.identity(true);
                        }
                    })
                    .catch(function(response) {
                        Info.addError("Wystąpił błąd podczas pobierania dokumentu.");
                    })
                    .finally(function() {
                        Info.clearAction();
                        Principal.identity(force);
                    });
            }
        }

        function downloadMergedPDF() {
                    Info.clear();
                    Info.setAction("Pobieranie dokumentów...");

                    //Validate the filter
                    vm.validateFilter(vm.filter.current);
                    //Get documents JSON by new query
                    vm.filter.current.sorting_order = vm.sortingOrder;
                    DocumentsService.downloadMergedPDFPaged(vm.filter.current, vm.page.number)
                        //If successful
                        .then(function(response) {
                           var file = new Blob([response.data], { type: "application/pdf" });
                           FileSaver.saveAs(file,
                               "Dokumenty.pdf");

                           var responseHeaders = response.headers();

                           var lastPage = responseHeaders.to_page;

                           if (lastPage > vm.page.totalPages) {
                                lastPage = vm.page.totalPages;
                           }
                           Info.addStatement("Pomyślnie pobrano dokumenty. Plik zawiera dokumenty ze stron " + vm.page.number + " - " + lastPage + ".");
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        })
                        .finally(function(response) {
                            Info.clearAction();
                            Principal.identity(force);
                        });
                }

        function downloadXML(event, index, document) {
            event.stopPropagation();
            if (document.e_invoice || !vm.client) {
                Info.setAction("Pobieranie dokumentu...");
                DocumentsService.getXML(document.transaction_id, document.login)
                    .then(function(response) {
                        var file = new Blob([response.data], { type: "application/xml" });
                        FileSaver.saveAs(file,
                            "Dokument nr " + document.document_nr + " z dnia " + new Date().toLocaleDateString() +
                            ".xml");
                        Info.addStatement("Pomyślnie pobrano dokument.");

                        //Update date opened
                        if (vm.client && !document.date_document_open) {
                            document.date_document_open = new Date().getTime();
                            Principal.identity(true);
                        }
                    })
                    .catch(function(response) {
                        Info.addError("Wystąpił błąd podczas pobierania dokumentu.");
                    })
                    .finally(function() {
                        Info.clearAction();
                        Principal.identity(force);
                    });
            }
        }

        function downloadExcel(event) {
            Info.setAction("Pobieranie arkusza Excel...");
            //Notify the user of downloading and disable the button
            event.target.disabled = true;
            event.target.innerText = "POBIERANIE...";

            vm.filter.current.sorting_order = vm.sortingOrder;
            DocumentsService.getExcelSpreadsheetPaged(vm.filter.current, vm.page.number)
                .then(function(response) {
                    var file = new Blob([response.data], { type: "application/vnd.ms-excel" });
                    FileSaver.saveAs(file,
                        "Dokumenty z dnia " + new Date().toLocaleDateString() +
                        ".xls");

                    var responseHeaders = response.headers();

                    var lastPage = responseHeaders.to_page;

                    if (lastPage > vm.page.totalPages) {
                        lastPage = vm.page.totalPages;
                    }
                    Info.addStatement("Pomyślnie pobrano arkusz Excel. Arkusz zawiera dokumenty ze stron " + vm.page.number + " - " + lastPage + ".");
                })
                .catch(function(response) {
                    Info.addError("Wystąpił błąd podczas pobierania arkusza Excel.");
                })
                .finally(function() {
                    Info.clearAction();
                });

            //Notify the user that the document has been downloaded and enable the button
            event.target.disabled = false;
            event.target.innerText = "POBIERZ JAKO XLS";
        }

        function getDocumentTypes($event) {
            if (vm.types.length == 0) {
                DocumentsService.getDocumentTypes(Principal.getLogin())
                    .then(function(response) {
                        response.data.result.sort();
                        for (var type in response.data.result) {
                            vm.types.push({
                                label: response.data.result[type].toUpperCase(),
                                title: response.data.result[type],
                                value: response.data.result[type]
                            });
                        }
                        $('#docTypeDropdown').multiselect('dataprovider', vm.types);
                        $('#docTypeDropdown').multiselect('rebuild');
                        $('#docTypeDropdown').multiselect('select', vm.filter.query.document_types);
                    });
            }
        }

        function sortBy(property) {
            var propertyObject = $filter('filter')(vm.sortingOrder, {'field':property})[0]
            var index = vm.sortingOrder.indexOf(propertyObject);

            if (index > 0) {
                vm.sortingOrder.splice(index, 1);
                vm.sortingOrder.unshift(propertyObject);
            }

            if (propertyObject.order == "ASC") {
                propertyObject.order = "DESC";
            } else {
                propertyObject.order = "ASC";
            }
            getDocs();
        }

        function isSortedWithOrder(property, order) {
            var propertyObject = $filter('filter')(vm.sortingOrder, {'field':property})[0]
            var index = vm.sortingOrder.indexOf(propertyObject);

            return index == 0 && propertyObject.order === order;
        }


        function getValue(value) {
            //Get value with two decimal digits
            return parseFloat(value).toFixed(2);
        }

        function generateExcelDownloadLink() {
            var filter = vm.filter.current;
            var link = API_URL + "api/documents/excel?";
            for (var key in filter) {
                //If the value matched by key is an empty string
                if (isFieldFilled(filter[key])) {
                    link += (key + "=" + filter[key] + "&");
                }
            }
            return link;
        }

        function isFilterFilled(filter) {
            if (vm.isFieldFilled(filter.login) || vm.isFieldFilled(filter.agreement_nr) || vm.isFieldFilled(filter.document_nr))
                return true;
            else
            //If there are no mandatory values in the filter return false
                return false;
        }

        function isFieldFilled(field) {
            if (field != null && field !== '' && field === field)
                return true;
            else
                return false;
        }

        function validateFilter(filter) {
            //Check all keys
            for (var key in filter) {
                //If the value matched by key is empty
                if (!isFieldFilled(filter[key])) {
                    //Substitue it with null
                    filter[key] = null;
                }
            }
        }

        function getDate(date) {
            return Utils.getDate(date);
        }

        function isDocumentRead(document) {
            if (document.isRead === true || document.isRead === false)
                return document.isRead;

            var isRead;

            //E-invoice
            if (!document.e_invoice)
                isRead = false;
            //Employee
            else if (Principal.getLogin() !== document.login)
                isRead = document.first_document_view_date != null;
            //Client
             else
                isRead = document.first_document_view_date != null && document.first_document_view_date < Principal.getLastLoginDate();

            document.isRead = isRead; //Persist read state to prevent recalculation
            return isRead;
        }

        function checkIfErrorPaymentDate(document){

            var idx = vm.additionalInfo.documents.indexOf(document.document_nr);

            return idx >= 0;

            
        }

        function getDocumentRowToolTip(document){

            var tipText = ''

            Principal.isClient()
            console.info(vm.clientEInvoice);

            if(document.e_invoice){
                tipText += 'Dokument (.pdf), kliknij by pobrać<BR>';
            }
            if(!document.e_invoice && (vm.clientEInvoice || !vm.isClient)){
                tipText += 'Dokument został wystawiony w wersji tradycyjnej i wysłany pocztą<BR>';
            }
            if(!document.e_invoice && (!vm.clientEInvoice && vm.isClient)){
                tipText += 'Podgląd niedostępny, dokument został wystawiony w wersji tradycyjnej i wysłany pocztą<BR>';
            }
            if(vm.additionalInfo != null && checkIfErrorPaymentDate(document)){
                tipText += '<B>Uwaga! Błędny termin płatności na fakturze nr '+document.document_nr+'. Prawidłowy termin to 30-06-2019. Prosimy o opłacenie należności do 30-06-2019</B>';
            }
            return tipText;
        }



        //Init controller
        vm.init();
    }
})();
