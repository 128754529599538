(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('ShellService', ShellService);
	    ShellService.$inject = ['API_URL','$http'];


    function ShellService (API_URL, $http) {
		        return {
			    'logout': function(){
				    return $http({
				      method: 'POST',
				      url: API_URL + 'api/auth/logout'
				});
			},
	communique1: function (line1) {
             return $http({
                    method: 'GET',
                    url: API_URL + '/api/communique/communique1/'+ line1
             });
	         },
	communique2: function (line1) {
             return $http({
                    method: 'GET',
                    url: API_URL + '/api/communique/communique2/'+ line1
             });
	         },
    dateStart: function () {
             return $http({
             method: 'GET',
             url: API_URL + '/api/communique/date1'
             });
             },
    dateEnd: function () {
             return $http({
             method: 'GET',
             url: API_URL + '/api/communique/date2'
             });
             },
    getLine1: function(data) {
             return $http({
             method: 'POST',
             url: API_URL + 'api/communique/update',
             data: data
             });
             }
    };
    }
})();
