(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('RoleController', RoleController);

    RoleController.$inject = ['$state', '$stateParams', 'RolesService', 'DateUtils', 'PermissionsService', 'Info', 'Principal'];

    function RoleController($state, $stateParams, RolesService, DateUtils, PermissionsService, Info, Principal) {
        var vm = this;

        //Declare variables
        vm.data = null;
        vm.warnings = [];

        //Declare functions
        vm.init = init;
        vm.getRole = getRole;
        vm.getRolePermissions = getRolePermissions;
        vm.getPermissions = getPermissions;
        vm.updateRole = updateRole;
        vm.generateUpdateObject = generateUpdateObject;
        vm.deleteRole = deleteRole;
        vm.getRoleLogs = getRoleLogs;
        vm.Date = DateUtils.convertDateTimeFromServer;
        vm.resetFilters = resetFilters;
        vm.getLogQueryObject = getLogQueryObject;

        //Init functions
        function init() {
            //Init data
            vm.data = {
                role: null,
                permissions: null,
                logs: null
            };

            if ($stateParams.role_id != null) {
                vm.getPermissions($stateParams.role_id);
                vm.getRole($stateParams.role_id);
            }

            vm.getRoleLogs();
        }

        function getRole(role_id) {
            //Get role JSON
            RolesService.getRole(role_id)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.role = response.data.result;
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getRolePermissions(role_id) {
            //Get role JSON
            RolesService.getRolePermissions(role_id)
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    for (var userPermission in response.data.result.permissions) {
                        for (var permission in vm.data.permissions) {
                            if (vm.data.permissions[permission].perm_id == response.data.result.permissions[userPermission].perm_id) {
                                vm.data.permissions[permission].checked = true;
                            }
                        }
                    }
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getPermissions() {
            //Get role JSON
            PermissionsService.getPermissions()
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.permissions = response.data.result.permissions;
                    vm.getRolePermissions($stateParams.role_id);
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function updateRole() {
            // Update role
            RolesService.updateRole(vm.data.role)
                //If successful
                .then(function(response) {
                    // Update role permissions
                    RolesService.updateRolePermissions(vm.generateUpdateObject())
                        //If successful
                        .then(function(response) {
                            Info.addStatement('Pomyślnie zaktualizowano rolę.', 'roles');
                            $state.go('roles');
                        })
                        //If there were errors
                        .catch(function(response) {
                            Info.addErrorCode(response.data.errorCode);
                        });
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function generateUpdateObject() {
            var perm_ids = [];
            for (var permission in vm.data.permissions) {
                if (vm.data.permissions[permission].checked)
                    perm_ids.push(vm.data.permissions[permission].perm_id);
            }

            return {
                role_id: parseInt($stateParams.role_id),
                perm_ids: perm_ids
            };
        }

        function getRoleLogs() {
                   //Principal.hasAuthority('LOGS_EMPLOYEE_RESOURCE').then(function(response) {
                    //    if (response) {
                            //Get role JSON
                            RolesService.getRoleLogs(vm.getLogQueryObject())
                                //If successful
                                .then(function(response) {
                                    vm.data.logs = response.data.result.logs;
                                    $state.transitionTo('role', vm.getLogQueryObject(), {
                                        location: true,
                                        inherit: true,
                                        relative: $state.$current,
                                        notify: false
                                    });
                                })
                                //If there were errors
                                .catch(function(response) {
                                    Info.addErrorCode(response.data.errorCode);
                                });
                   //     }
                   // });
        }

        vm.filter = {
            role_id: $stateParams.role_id,
            ts_from: null,
            ts_to: null
        };

        function getLogQueryObject() {
            var filter = jQuery.extend(true, {}, vm.filter);
            filter.relative_id = filter.role_id;
            return filter;
        }

        function resetFilters() {
                    vm.filter = {
                        role_id: $stateParams.role_id,
                        ts_from: null,
                        ts_to: null
                    };
        }


        function deleteRole() {
            //Get role JSON
            RolesService.deleteRole(vm.data.role.role_id)
                //If successful
                .then(function(response) {
                    Info.addStatement('Pomyślnie usunięto rolę ' + vm.data.role.label + '.', 'roles');
                    $state.go('roles');
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                });
        }

        function getDate(date) {
                    var result = new Date(0);
                    result.setSeconds(0);
                    result.setMinutes(0);
                    result.setHours(0);
                    result.setDate(date.split('.')[0]);
                    result.setMonth(date.split('.')[1] - 1);
                    result.setYear(date.split('.')[2]);
                    return result.getTime();
                }

        function now() {
                    return new Date().getTime();
                }

        //Init controller
        vm.init();
    }
})();
