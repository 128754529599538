(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('patternTooltip', ['Principal', function(Principal) {

            return {
                restrict: 'A',
                link: function(scope, element, attrs) {
                    //Add usage of HTML tags in a tooltip
                    $(element).attr('data-html', true);
                    //Make tooltip appear on demand, rather than on hover
                    $(element).attr('data-trigger', 'focus');

                    //On each keyup
                    $(element).keyup(jQuery.proxy(function(scope) {
                        var tooltip = '';
                        //VALIDATE
                        //If input did not match the pattern
                        if (this.hasClass('ng-invalid-pattern')) {
                            tooltip += "<p style='font-size:10pt;font-weight:normal;margin:0px;'>" + this.attr('data-pattern-tooltip') + '</p>';
                        }

                        //SHOW AND HIDE TOOLTIP AUTOMATICALLY ON VALIDITY CHANGE
                        if (this.attr('data-original-title') != tooltip) {
                            //Change title
                            this.attr('data-original-title', tooltip);
                            this.tooltip('fixTitle');
                            //If title is blank then hide tooltip and make field valid
                            if (tooltip == '') {
                                this.tooltip('hide');
                                //Else show tooltip with new title
                            } else if ($(this)[0] === document.activeElement) {
                                this.tooltip('show');
                            }
                        }
                    }, element));

                    //On change
                    $(element).change(jQuery.proxy(function() {
                        this.trigger('keyup');
                    }, element));

                    //On lose focus
                    $(element).blur(jQuery.proxy(function() {
                        this.trigger('keyup');
                    }, element));
                }
            };
        }]);
})();
