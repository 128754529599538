(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('PasswordExpiredController', PasswordExpiredController);

    PasswordExpiredController.$inject = ['$rootScope', '$state', '$stateParams', 'PassworExpiredService', 'Info'];

    function PasswordExpiredController($rootScope, $state, $stateParams, PassworExpiredService, Info) {
        var vm = this;

        //Variables
        vm.data = {
            login: $stateParams.login,
            currentValue: $stateParams.currentValue,
            newValue: null
        };

        vm.changePassword = changePassword;

        function changePassword() {
            $('.content').addClass('hidden');
            $('.loader').removeClass('hidden');
            PassworExpiredService.changeExpiredPassword(vm.data)
                .then(function(response) {
                    $state.go('passwordExpired-Success');
                    $('.content').removeClass('hidden');
                    $('.loader').addClass('hidden');
                })
                .catch(function(response) {
                    $('.content').removeClass('hidden');
                    $('.loader').addClass('hidden');
                    Info.addErrorCode(response.data.errorCode);
                });
        }
    }
})();
