(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('Register3Controller', Register3Controller);

    Register3Controller.$inject = ['$state', '$stateParams', '$location', 'RegisterService', 'Info', 'Utils', 'registration'];

    function Register3Controller($state, $stateParams, $location, RegisterService, Info, Utils, registration) {
        var vm = this;


        vm.location = $location;

        vm.data = registration ? registration : {
            agreement1: null,
            agreement2: null,
            agreement3: null,
            agreement4: null,
            agreement5: null,
            agreement6: null,
            agreement7: null
        };

        angular.merge(vm.data, RegisterService.registration);

        //Declare functions
        vm.proceed = proceed;
        vm.back = back;

        function proceed(event) {
            $('.content').addClass('hidden');
            $('.loader.registrator').removeClass('hidden');
            //Notify the user of sending and disable the button
            event.target.innerText = "Wysyłanie...";
            event.target.disabled = true;
            RegisterService.step3(vm.data)
                //If successful
                .then(function(response) {
                    //Change URL to match the new query
                    $state.go('register-finish');
                    $('.content').removeClass('hidden');
                    $('.loader.registrator').addClass('hidden');
                })
                //If there were errors
                .catch(function(response) {
                    if ([202].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register2');
                        $state.go('register2');
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    } else if ([205, 206].indexOf(response.data.errorCode) != -1) {
                        Info.addErrorCode(response.data.errorCode, 'register1');
                        $state.go('register1');
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    } else {
                        Info.addErrorCode(response.data.errorCode, 'register-fail');
                        $state.go('register-fail-' + response.data.errorCode);
                        $('.content').removeClass('hidden');
                        $('.loader.registrator').addClass('hidden');
                    }
                    //Enable the button and return to former text
                    event.target.innerText = "Wyślij";
                    event.target.disabled = false;
                    $('.content').removeClass('hidden');
                    $('.loader.registrator').addClass('hidden');
                });
        }

        function back() {
            $state.go('register2');
            RegisterService.persist(vm.data);
        }
    }
})();
