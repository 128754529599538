(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('RolesController', RolesController);

    RolesController.$inject = ['RolesService', 'Info'];

    function RolesController(RolesService, Info) {
        var vm = this;

        //Declare variables
        vm.data = null;
        vm.warnings = [];

        //Declare functions
        vm.init = init;
        vm.getRoles = getRoles;

        //Init functions
        function init() {
            //Init data         
            vm.data = {
                roles: null
            };

            //Get documents from server
            vm.getRoles();
        }

        function getRoles() {
            Info.clear();
            Info.setAction("Wyszukiwanie ról...");
            //Get documents JSON by new query
            RolesService.getRoles()
                //If successful
                .then(function(response) {
                    //Set data to controller data
                    vm.data.roles = response.data.result.roles;
                })
                //If there were errors
                .catch(function(response) {
                    Info.addErrorCode(response.data.errorCode);
                })
                .finally(function() {
                    Info.clearAction();
                });
        }

        //Init controller
        vm.init();
    }
})();
