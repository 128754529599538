(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('ResetService', ResetService);

    ResetService.$inject = ['API_URL','$http'];

    function ResetService (API_URL, $http) {
 
		        return {
		};
	    }
})();
