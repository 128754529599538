(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .controller('ResetController', ResetController);

    ResetController.$inject = ['$state', 'ResetService'];

    function ResetController ($state, ResetService) {
		        var vm = this;

		//Declare functions
		        vm.reset = reset;
		
		        function reset(){
			    setTimeout(function(){
				    $state.go('login');
			},3000);			
		}
    }
})();
