(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .directive('equals', function() {

            return {
                require: 'ngModel',
                restrict: 'A',
                link: function(scope, element, attrs, ctrl) {
                    element.change(jQuery.proxy(function(scope) {
                        var toEqual = this.attr('equals');
                        var value = this.val();
                        var message = this.attr('data-equals-message');
                        //Two values do not match
                        if (toEqual != value) {
                            var tooltip = '<p style="font-size:10pt;font-weight:normal;margin:0px;">' + message + '</p>';
                            this.attr('data-original-title', tooltip);
                            this.tooltip('fixTitle');
                            this.tooltip('show');
                            this[0].classList.add('ng-invalid');
                        } else {
                            this.tooltip('hide');
                        }
                    }, element));
                }
            };
        });
})();
