(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('Info', Info);

    Info.$inject = ['$state'];

    function Info($state) {
        var _errors = {},
            _statements = {},
            _infos = {},
            _action = [];

        return {
            action: _action,
            errors: _errors,
            statements: _statements,
            infos: _infos,
            addInfo: addInfo,
            addStatement: addStatement,
            addError: addError,
            addErrorCode: addErrorCode,
            setAction: setAction,
            clearAction: clearAction,
            clear: clear
        };

        function addInfo(info, state) {
            //If the state has not been passed
            if (state == undefined)
            //Set it as current state
                state = $state.current.name;
                //If the container for 'state' is not initialized
            if (_infos[state] == undefined)
            //Initialize it as an array
                _infos[state] = [];

            var container = _infos[state];
            var index = container.indexOf(info);
            // If the info already is in _infos
            if (index != -1) {
                // Remove it from _infos
                container.splice(index, 1);
            }
            // Add the info to _infos
            container.push(info);
        }

        function addStatement(statement, state) {
            //If the state ahs not been passed
            if (state == undefined)
            //Set it as current state
                state = $state.current.name;
                //If the container for 'state' is not initialized
            if (_statements[state] == undefined)
            //Initialize it as an array
                _statements[state] = [];

            var container = _statements[state];
            var index = container.indexOf(statement);
            // If the statement already is in _statements
            if (index != -1) {
                // Remove it from _statements
                container.splice(index, 1);
            }
            // Add the statement to _statements
            container.push(statement);
        }

        function addError(error, state) {
            //If the state ahs not been passed
            if (state == undefined)
            //Set it as current state
                state = $state.current.name;
                //If the container for 'state' is not initialized
            if (_errors[state] == undefined)
            //Initialize it as an array
                _errors[state] = [];

            var container = _errors[state];
            var index = container.indexOf(error);
            // If the error already is in _errors
            if (index != -1) {
                // Remove it from _errors
                container.splice(index, 1);
            }
            // Add the error to _errors
            container.push(error);
        }

        function addErrorCode(errorCode, state, detail) {
            //If the state has not been passed
            if (state == undefined)
            //Set it as current state
                state = $state.current.name;
                // Choose error message corresponding to the error code and add it to errors
            switch (errorCode) {
                //VALIDATION
            case 101:
                addError('Nie podano KODU KLIENTA, ani NUMERU UMOWY.', state);
                break;
            case 102:
                addError('Nie podano KODU lub E-MAILA klienta.', state);
                break;
            case 103:
                addError('Nie podano ID klienta.', state);
                break;
            case 104:
                addError('Nie podano hasła.', state);
                break;
            case 105:
                addError('Nie podano żadnej wartości do zaktualizowania.', state);
                break;
            case 106:
                addError('Nie wypełniono wszystkich pól.', state);
                break;
            case 107:
                addError('Nie podano wymaganych pól.', state);
                break;
            case 108:
                addError('Nie wypełniono wszystkich pól.', state);
                break;
            case 109:
                addError('Nie podano ID roli.', state);
                break;
            case 1010:
                addError('Nie podano żadnej wartości do zaktualizowania.', state);
                break;
            case 1011:
                addError('Nie podano ID roli.', state);
                break;
            case 1012:
                addError('Nie podano żadnych uprawnień dla tej roli.', state);
                break;
            case 1013:
                addError('Nie wypełniono wszystkich pól.', state);
                break;
            case 1014:
                addError('Nie podano ID użytkownika.', state);
                break;
            case 1015:
                addError('Nie podano żadnej wartości do zaktualizowania.', state);
                break;
            case 1016:
                addError('Nie podano ID użytkownika.', state);
                break;
            case 1017:
                addError('Nie podano żadnej roli dla tego użytkownika.', state);
                break;
            case 1018:
                addError('Podaj interwał dłuższy lub równy 7 dni.', state);
                break;
            case 1019:
                addError('Podane aktualne hasło jest nieprawidłowe.', state);
                break;
            case 1020:
                addError('Podany stary adres e-mail nie jest taki sam, jak aktualny adres e-mail.', state);
                break;
            case 1027:
                addError('Nie podano tokenu.', state);
                break;
            case 1028:
                addError('Nie podano nowego hasła dwukrotnie.', state);
                break;
            case 1029:
                addError('Podane hasła nie są jednakowe.', state);
                break;
            case 1031:
                addError('Podane hasło nie spełnia wymogów bezpieczeństwa.', state);
                break;
            case 1401:
                addError('Nie potwierdzono zgód lub sesja wygasła. Kliknij ponownie w link aktywacyjny.', state);
                break;

                    //RESOURCE
            case 201:
                addError('Podany login już istnieje.', state);
                break;
            case 202:
                addError('Podany nowy adres e-mail już istnieje.', state);
                break;
            case 203:
                addError('Błąd podczas generowania dokumentu Excel.', state);
                break;
            case 204:
                addError('Podany klient jest już zarejestrowany.', state);
                break;
            case 205:
                addError('Podany klient nie istnieje.', state);
                break;
            case 206:
                addError('Podany dokument nie istnieje.', state);
                break;
            case 207:
                addError('Klient o podanym numerze jest już zarejestrowany w Portalu VB Leasing.', state);
                break;
            case 208:
                addError('Rejestracji o podanym tokenie nie ma w bazie.', state);
                break;
            case 209:
                addError('Klient o podanym numerze nie posiada aktywnej umowy z VB Leasing.', state);
                break;
            case 2010:
                addError('Podany typ szablonu dokumentu jest niepoprawny.', state);
                break;
            case 2011:
                addError('Podany typ dokumentu jest niepoprawny.', state);
                break;
            case 2012:
                addError('Wystąpił błąd podczas pobierania dokumentu.', state);
                break;
            case 2013:
                addError('Klient o podanym numerze jest już zarejestrowany w Portalu VB Leasing.', state);
                break;
            case 2014:
                addError('Podane hasło startowe nie jest takie samo, jak hasło startowe podane w pierwszym etapie rejestracji.', state);
                break;
            case 2015:
                addError('Klient o podanym numerze nie posiada dostępu do Portalu VB Leasing.', state);
                break;
            case 2021:
                addError('Klient o podanym numerze nie może zarejestrować się za pomocąszybkiej metody. Proszę użyć <a href="#/register/1">formularza rejestracyjnego</a>.', state);
                break;
            case 2023:
                addError('Podane hasło nie jest prawidłowe.', state);
                break;

                    //DATABASE
            case 301:
                addError('Błąd połączenia z bazą danych.', state);
                break;
            case 302:
                addError('Błędne zapytanie SQL.', state);
                break;

                    //AUTHENTICATION
            case 401:
                addError('Podane konto nie istnieje.', state);
                break;
            case 402:
                switch (detail) {
                    case 1:
                        addError('Podano nieprawidłowy login i/lub hasło.', state);
                        break;
                    case 2:
                        addError('Podano nieprawidłowe hasło. Kolejna błędna próba logowania spowoduje zablokowanie dostępu do Portalu VB Leasing na 15 minut. Jeśli nie pamiętasz hasła <a href="#/passwordReset">kliknij</a>.', state);
                        break;
                    case 3:
                    case 4:
                    case 5:
                        addError('Podano nieprawidłowe hasło. Konto zostało zablokowane na 15 minut.', state);
                        break;
                    default:
                        addError('Podano nieprawidłowy login i/lub hasło.', state);
                        break;
                    }
                break;
            case 403:
                addError('Konto zostało zablokowane. W celu odblokowania dostępu do Portalu VB Leasing prosimy o kontakt z Działem Obsługi Klienta. Infolinia: 801 199 199 / 71 33 44 900, czynna jest w dni robocze w godz. 8-16. Zgłoszenia można dokonać także drogą elektroniczną: <a href="mailto:portal.klienta@vbl.pl">portal.klienta@vbl.pl</a>.', state);
                break;
            case 404:
                addError('Zbyt dużo prób logowania.', state);
                break;
            case 405:
                addError('Podany użytkownik jest już zalogowany.', state);
                break;
            case 407:
                addError('Podany użytkownik nie posiada aktywnego konta.', state);
                break;
            case 408:
                addError('Podany użytkownik nie ma dostępu do Portalu.', state);
                break;
            case 409:
                var remaining = Math.ceil((1000 /*ms*/ * 60 /*s*/ * 15 /*min*/ - detail) / 1000 /*ms*/ / 60 /*s*/ );
                addError('Konto będzie zablokowane przez: ' + remaining + ' minut(y). Przez ten czas nie jest możliwe zalogowanie się do Portalu VB Leasing.', state);
                break;
            case 4012:
                addError('Hasło do tego konta zostało zresetowane. Proszę sprawdzić swoją skrzynkę pocztową.', state);
                break;

            default:
                addError('Nieznany błąd.', state);
                break;
            }
        }

        function setAction(act) {
            clearAction();
            _action.push(act);
        }

        function clearAction() {
            _action.splice(0, _action.length);
        }

        function clear(state) {
            clearAction();
            //If the state has not been passed
            if (state == undefined)
            //Set it as current state
                state = $state.current.name;

            //If _errors for this state are undefined
            if (_errors[state] == undefined)
            //Initialize a new array
                _errors[state] = [];
            else
            //Clear the array
                _errors[state].splice(0, _errors[state].length);

            //If _statements for this state are undefined
            if (_statements[state] == undefined)
            //Initialize a new array
                _statements[state] = [];
            else
            //Clear the array
                _statements[state].splice(0, _statements[state].length);

            //If infos for this state are undefined
            if (_infos[state] == undefined)
            //Initialize a new array
                _infos[state] = [];
            else
            //Clear the array
                _infos[state].splice(0, _infos[state].length);
        }
    }
})();
