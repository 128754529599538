(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('user', {
            parent: 'application',
            url: '/user?user_id',
            params: {
                user_id: null
            },
            data: {
                authorities: ['USER_RESOURCE'],
                pageTitle: 'Informacja o użytkowniku - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/user/user.html',
                    controller: 'UserController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
