(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('users', {
            parent: 'application',
            url: '/users?login&email&enable',
            params: {
                login: null,
                email: null,
                enable: null
            },
            data: {
                authorities: ['USER_RESOURCE'],
                pageTitle: 'Użytkownicy - VB Leasing'
            },
            views: {
                'content@application': {
                    templateUrl: 'app/views/users/users.html',
                    controller: 'UsersController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
