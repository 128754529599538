(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .factory('RegisterService', RegisterService);

    RegisterService.$inject = ['API_URL', '$http'];

    function RegisterService(API_URL, $http) {
        var service = {
            'registration': {},
            'persist': function(registrationData) {
                angular.merge(service.registration, registrationData);
            },
            'step1': function(data) {
                service.persist(data);
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register/1',
                    data: data
                });
            },
            'step2': function(data) {
                service.persist(data);
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register/2',
                    data: data
                });
            },
            'step3': function(data) {
                service.persist(data);
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register/3',
                    data: data
                });
            },
            'register': function(data) {
                return $http({
                    method: 'POST',
                    url: API_URL + 'api/account/register',
                    data: data
                });
            },
            'getRegistration': function(data) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/register',
                    params: data
                });
            },
            activate: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/register',
                    data: data
                });
            },
            quickActivate: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/register/quick/end',
                    data: data
                });
            },
            userActivate: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/register/user',
                    data: data
                });
            },
            getApprovalData: function(data) {
                return $http({
                    method: 'GET',
                    url: API_URL + 'api/account/register/quick/data',
                    params: data
                });
            },
            spreadApproval: function(data) {
                return $http({
                    method: 'PUT',
                    url: API_URL + 'api/account/register/quick/approval',
                    data: data
                });
            }
        };

        return service;
    }
})();
