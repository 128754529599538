(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('emailChange', {
            parent: 'site',
            url: '/emailChange?token',
            params: {
                token: null
            },
            data: {
                authorities: [],
                pageTitle: 'Zmiana adresu e-mail - VB Leasing'
            },
            views: {
                'index@': {
                    templateUrl: 'app/views/emailChange/emailChange.html'
                }
            }
        }).state('emailChange-Success', {
            parent: 'emailChange',
            url: '/success',
            views: {
                'step@emailChange': {
                    templateUrl: 'app/views/emailChange/emailChange-Success.html',
                }
            },
            resolve: {
                change: function($rootScope, $q, $timeout, $state, $stateParams, Utils, AccountService) {
                    var deferred = $q.defer();
                    var fromState = $rootScope.fromState;

                    // $timeout is an example; it also can be an xhr request or any other async function
                    $timeout(jQuery.proxy(function() {
                        if (Utils.isEmpty($stateParams.token)) {
                            $state.go(fromState);
                            deferred.reject();
                        } else {
                            AccountService.getChange($stateParams)
                                .then(function(response) {
                                    if (response.data.result != null) {
                                        AccountService.applyChange($stateParams)
                                            .then(function() {
                                                deferred.resolve(response.data.result);
                                            }).catch(function(response) {
                                                $state.go('emailChange-Fail-' + response.data.errorCode);
                                                deferred.reject();
                                            });
                                    } else {
                                        $state.go(fromState);
                                        deferred.reject();
                                    }
                                })
                                .catch(function(response) {
                                    $state.go('emailChange-Fail-' + response.data.errorCode);
                                    deferred.reject();
                                });
                        }
                    }, this));
                    return deferred.promise;
                }
            }
        }).state('emailChange-Fail', {
            parent: 'emailChange',
            url: '/fail',
            views: {
                'step@emailChange': {
                    templateUrl: 'app/views/emailChange/emailChange-Fail.html'
                }
            }
        });

        var errors = [208, 2013, 2016];
        //Add all error states
        for (var errorIndex in errors) {
            $stateProvider.state('emailChange-Fail-' + errors[errorIndex], {
                parent: 'emailChange-Fail',
                url: '/' + errors[errorIndex],
                views: {
                    'fail@emailChange-Fail': {
                        templateUrl: 'app/views/emailChange/emailChange-Fail-' + errors[errorIndex] + '.html'
                    }
                }
            });
        }
    }
})();
