(function() {
    'use strict';

    angular
        .module('edistradaIdealeasingPortalApp', [
            'ngAnimate',
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngSanitize',
            'ngCacheBuster',
            'ngFileUpload',
            'ngFileSaver',
            'ngLoadingSpinner',
            'ui.bootstrap',
            'ui.router'
        ])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope', 'Principal', 'Info', '$sce', '$urlRouter', '$location'];

    function run(stateHandler, $rootScope, Principal, Info, $sce, $urlRouter, $location) {
        stateHandler.initialize();
        $rootScope.Principal = Principal;
        $rootScope.Info = Info;
        $rootScope.$sce = $sce;
        $rootScope.$location = $location;
        var test = 1;
    }
})();
